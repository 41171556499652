import axios from "axios";
import { IPort } from "../Models/IPort";
import { apiUrl } from "./ApiService";
export class PortServices {
  public static async GetPorts(): Promise<IPort[]> {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/port/get-ports`);
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
