import axios from "axios";
import Cookies from "universal-cookie";
import { UserInfo } from "../Models/IUserInfo";
import { apiUrl } from "./ApiService";
export class ReservationImpressionServices {
  public static async GetFicheReservation(
    reservationId: string
  ): Promise<boolean> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    try {
      const response = await axios.get(
        `${apiUrl}/api/reservation-impression/get-fiche-reservation/${reservationId}`,
        {
          headers: {
            Authorization: `Bearer ${_userInfo.token}`,
          },
          responseType: "arraybuffer",
        }
      );

      const headerLine = response.headers["content-disposition"];
      const filename = headerLine
        .split(";")[2]
        .replace("filename*=UTF-8''", "")
        .trim();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", decodeURIComponent(filename));
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (error) {
      return false;
    }
  }

  public static async GetCarteEmbarquement(
    passagerId: string
  ): Promise<boolean> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    try {
      const response = await axios.get(
        `${apiUrl}/api/reservation-impression/get-carte-embarquement/${passagerId}`,
        {
          headers: {
            Authorization: `Bearer ${_userInfo.token}`,
          },
          responseType: "arraybuffer",
        }
      );

      const headerLine = response.headers["content-disposition"];
      const filename = headerLine
        .split(";")[2]
        .replace("filename*=UTF-8''", "")
        .trim();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", decodeURIComponent(filename));
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (error) {
      return false;
    }
  }

  public static async GetLettreTransport(
    reservationId: string
  ): Promise<boolean> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    try {
      const response = await axios.get(
        `${apiUrl}/api/reservation-impression/get-lettre-transport/${reservationId}`,
        {
          headers: {
            Authorization: `Bearer ${_userInfo.token}`,
          },
          responseType: "arraybuffer",
        }
      );

      const headerLine = response.headers["content-disposition"];
      const filename = headerLine
        .split(";")[2]
        .replace("filename*=UTF-8''", "")
        .trim();

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", decodeURIComponent(filename));
      document.body.appendChild(link);
      link.click();

      return true;
    } catch (error) {
      return false;
    }
  }
}
