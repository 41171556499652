import { styled } from "@fluentui/react/lib/Utilities";
import { ICartProps } from "./Cart.types";
import { getStyles, ICartStyleProps, ICartStyles } from "./Cart.styles";
import { CartComponent } from "./Cart.base";

/**
 * Cart
 */
export const Cart = styled<ICartProps, ICartStyleProps, ICartStyles>(
  CartComponent,
  getStyles
);
