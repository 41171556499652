import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IPrivacyPolicySubComponentStyles {}

export interface IPrivacyPolicyStyles
  extends IAppBaseStyles<IPrivacyPolicySubComponentStyles> {}

export interface IPrivacyPolicyStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getPrivacyPolicyClassNames = (
  styles?: IStyleFunctionOrObject<
    IPrivacyPolicyStyleProps,
    IPrivacyPolicyStyles
  >,
  props?: IPrivacyPolicyStyleProps
): IComponentBaseClassNames<
  IPrivacyPolicyStyleProps,
  IPrivacyPolicyStyles,
  IPrivacyPolicySubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPrivacyPolicyStyleProps,
    IPrivacyPolicyStyles,
    IPrivacyPolicySubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPrivacyPolicyStyleProps,
  IPrivacyPolicyStyles
> = (props: IPrivacyPolicyStyleProps): IPrivacyPolicyStyles => {
  return {
    root: ["app-PrivacyPolicy", {}],
  };
};
