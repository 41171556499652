import { styled } from "@fluentui/react/lib/Utilities";
import { IAppHeaderProps } from "./AppHeader.types";
import {
  getStyles,
  IAppHeaderStyleProps,
  IAppHeaderStyles,
} from "./AppHeader.styles";
import { AppHeaderComponent } from "./AppHeader.base";

/**
 * AppHeader
 */
export const AppHeader = styled<
  IAppHeaderProps,
  IAppHeaderStyleProps,
  IAppHeaderStyles
>(AppHeaderComponent, getStyles);
