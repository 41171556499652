import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { ILastNewsProps, ILastNewsState } from "./LastNews.types";
import i18n from "../../Services/i18n";

export class LastNewsComponent extends React.Component<
  ILastNewsProps,
  ILastNewsState
> {
  constructor(props: ILastNewsProps) {
    super(props);
    this.state = {
      isLoading: true,
      derniereNouvelleDto: { passagerLibelle: [], vehiculeLibelle: [] },
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("LastNews", true);
    let items = await UserAccountServices.GetLastNews();

    this.setState({ isLoading: false, derniereNouvelleDto: items });
    LoadingServices.setLoading("LastNews", false);
  }
  render(): JSX.Element {
    if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return (
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("LatestNews:Label:Titre")}
          
          render={
            <Stack
              style={{
                marginTop: "20px",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
            >
              {this.state.derniereNouvelleDto?.passagerLibelle.length > 0 ? (
                <Stack>
                  <Label>
                    {i18n.t("LatestNews:Label:EntetePsg")}
                    
                  </Label>
                  <Stack style={{ marginLeft: "10px", marginTop: "10px" }}>
                    {this.state.derniereNouvelleDto?.passagerLibelle.map(
                      (x, index) => {
                        return <li key={index}>{x}</li>;
                      }
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Label>
                  {i18n.t("LatestNews:Label:NoPassager")}
                  
                </Label>
              )}

              {this.state.derniereNouvelleDto?.vehiculeLibelle.length > 0 ? (
                <Stack style={{ marginTop: "20px" }}>
                  <Label>
                    {i18n.t("LatestNews:Label:EnteteVhc")}
                    
                  </Label>
                  <Stack style={{ marginLeft: "10px", marginTop: "10px" }}>
                    {this.state.derniereNouvelleDto?.vehiculeLibelle.map(
                      (x, index) => {
                        return <li key={index}>{x}</li>;
                      }
                    )}
                  </Stack>
                </Stack>
              ) : (
                <Label>
                {i18n.t("LatestNews:Label:NoVehicule")}
                  
                </Label>
              )}
            </Stack>
          }
        />
      );
    }
  }
}
