import {
  ComboBox,
  DefaultButton,
  Dropdown,
  IComboBoxOption,
  Label,
  MaskedTextField,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { IPaymentProps, IPaymentState } from "./Payment.types";
import Countdown from "react-countdown";
import CurrencyService from "../../Services/CurrencyService/CurrencyService";

export class PaymentComponent extends React.Component<
  IPaymentProps,
  IPaymentState
> {
  constructor(props: IPaymentProps) {
    super(props);
    this.state = {
      estTimerExpirer : false
    }
  }

  private _months: IComboBoxOption[] = [];
  private _years: IComboBoxOption[] = [];
  componentDidMount(): void {
    for (let i = 0; i < 12; i++) {
      this._months.push({ key: (i + 1).toString(), text: (i + 1).toString() });
    }
    let year = new Date().getFullYear();
    for (let i = 0; i < 15; i++) {
      this._years.push({ key: year, text: year.toString() });
      year++;
    }
  }

  renderCountDown() : JSX.Element {    
    if (this.estTimerExpirer()){
      return (
      <Stack horizontal style={{ marginTop: "25px" }}>
        <Label style={{ color: "red", marginLeft: "5px" }}>
          {i18n.t("Paiement:Title:Timesup")}                    
        </Label>
      </Stack>)      
    } else {
      return (
        <Countdown
          date={this.props.panierExpireLe}
          precision={2}
          onComplete = {()=> this.setState({'estTimerExpirer': true})}
          renderer={(props) => {
            let seconds =
              props.seconds < 10
                ? "0" + props.seconds.toString()
                : props.seconds.toString();

            let minutes =
              props.minutes < 10
                ? "0" + props.minutes.toString()
                : props.minutes.toString();

            if (this.estTimerExpirer()) {
              // Render a completed state
              return (
              <Stack horizontal style={{ marginTop: "25px" }}>
                  <Label style={{ color: "red", marginLeft: "5px" }}>
                    {i18n.t("Paiement:Title:Timesup")}                    
                  </Label>
                </Stack>
                )
            } else {
              // Render a countdown
              return (          
                <Stack style={{ marginTop: "25px", fontSize: this.props.mobile ? "12px": ""}}>
                  <div>
                    <span>
                      {i18n.t("Paiement:Title:Timer")}  
                    </span>
                    <span style={{ color: "red", marginLeft: "5px" }}>
                      {minutes}:{seconds}
                    </span>
                    <span style={{ marginLeft: "5px" }}>minutes.</span>
                  </div>
                </Stack>
              );
            }
          }}
        />
    )
    }   
  }

  render(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "20px" }}>        
        {this.props.panierExpireLe !== null && this.renderCountDown()}
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={i18n.t("Paiement:Title:Paiement")}
          render={
            <Stack
              style={{
                marginLeft: "30px",
                marginTop: "20px",
                marginBottom: "25px",
                width: this.props.mobile ? "80%" : "",
              }}
            >
              <Stack
                style={{ maxWidth: "200px" }}
                horizontal={!this.props.mobile}
                horizontalAlign="space-between"
              >
                <Label style={{ minWidth: this.props.mobile ? "" : "230px" }}>
                  {i18n.t("Paiement:Label:MontantTotal")}
                </Label>
                <Label>{CurrencyService.ToDollars(this.props.montantTotal)}</Label>
              </Stack>

              <Stack
                style={{ maxWidth: "450px", marginTop: "10px" }}
                horizontal={!this.props.mobile}
                horizontalAlign="space-between"
              >
                <Label style={{ minWidth: this.props.mobile ? "" : "230px" }}>
                  {i18n.t("Paiement:Label:NomProprio")}
                </Label>
                <TextField
                  onChange={(e, v) => {
                    if (v !== undefined) {
                      this.props.updateNomCarte(v);
                    }
                  }}
                />
              </Stack>

              <Stack
                style={{ maxWidth: "450px", marginTop: "10px" }}
                horizontal={!this.props.mobile}
                horizontalAlign="space-between"
              >
                <Label style={{ minWidth: this.props.mobile ? "" : "230px" }}>
                  {i18n.t("Paiement:Label:CartNumber")}
                </Label>
                <MaskedTextField
                  mask="9999 9999 9999 9999"
                  style={{ width: "180px" }}
                  onChange={(e, v) => {
                    if (v !== undefined) {
                      this.props.updateCreditCard(v);
                    }
                  }}
                />
              </Stack>
              <Stack
                style={{ maxWidth: "450px", marginTop: "10px" }}
                horizontal={!this.props.mobile}
                horizontalAlign="space-between"
              >
                <Label style={{ minWidth: this.props.mobile ? "" : "230px" }}>
                  {i18n.t("Paiement:Label:ValidationNumber")}
                </Label>
                <MaskedTextField
                  mask="999"
                  style={{ width: "70px" }}
                  onChange={(e, v) => {
                    if (v !== undefined) {
                      this.props.updateVerificationCode(v);
                    }
                  }}
                />
              </Stack>
              <Stack
                style={{ maxWidth: "450px", marginTop: "10px" }}
                horizontal={!this.props.mobile}
                horizontalAlign="space-between"
              >
                <Label style={{ minWidth: this.props.mobile ? "" : "230px" }}>
                  {i18n.t("Paiement:Label:DateDExpiration")}
                </Label>
                <Stack horizontal>
                  { this.renderExpiration() }
                </Stack>
              </Stack>
            </Stack>
          }
        />

        <Stack
          style={{ marginTop: "20px", width: "95%", marginBottom: "50px" }}
          horizontal
        >
          <DefaultButton
            style={{
              color: this.props.theme.palette.blueDark,
              backgroundColor: this.props.theme.palette.white,
              borderRadius: 10,
              height: "50px",
              width: this.props.mobile ? "170px" : "250px",
            }}
            onClick={() => {
              window.location.reload();
            }}
          >
            {i18n.t("Paiment:Label:Cancel")}
          </DefaultButton>
          <DefaultButton
            disabled={!this.props.canBePay || this.estTimerExpirer()}
            onClick={() => {
              this.props.updateStateComponent();
            }}
            style={{
              color: "white",
              backgroundColor: (this.props.canBePay && !this.estTimerExpirer())
                ? this.props.theme.palette.blueDark
                : this.props.theme.palette.neutralLight,
              borderRadius: 10,
              height: "50px",
              width: this.props.mobile ? "170px" : "250px",
              marginLeft: "15px",
            }}
          >
            {i18n.t("Paiement:Label:PayerMaintenant")}
          </DefaultButton>
        </Stack>
      </Stack>
    );
  }

  renderExpiration() {
    if (this.props.mobile){
      return (
        <>
          <Dropdown
            style={{ maxWidth: "80px" }}
            options={this._months}
            dropdownWidth={100}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateExpirationDateMonth(v.key.toString());
              }
            }}
          />
          <Dropdown
            style={{ marginLeft: "15px", maxWidth: "120px" }}
            options={this._years}
            dropdownWidth={100}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateExpirationDateYear(v.key.toString());
              }
            }}
          />
        </>
      )
    } else {
      return (
      <>
        <ComboBox
          style={{ maxWidth: "80px" }}
          options={this._months}
          dropdownWidth={100}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.props.updateExpirationDateMonth(v.key.toString());
            }
          }}
        />
        <ComboBox
          style={{ marginLeft: "15px", maxWidth: "120px" }}
          options={this._years}
          dropdownWidth={100}
          onChange={(e, v) => {
            if (v !== undefined) {
              this.props.updateExpirationDateYear(v.key.toString());
            }
          }}
        />
      </>
      )
    }

  }

  estTimerExpirer() : boolean {    
    const now = new Date();
    const expireLe = new Date(this.props.panierExpireLe);
    return (now>expireLe)
  }
}
