import { styled } from "@fluentui/react/lib/Utilities";
import { IServicesSupplementaireProps } from "./ServicesSupplementaire.types";
import {
  getStyles,
  IServicesSupplementaireStyleProps,
  IServicesSupplementaireStyles,
} from "./ServicesSupplementaire.styles";
import { ServicesSupplementaireComponent } from "./ServicesSupplementaire.base";

/**
 * ServicesSupplementaire
 */
export const ServicesSupplementaire = styled<
  IServicesSupplementaireProps,
  IServicesSupplementaireStyleProps,
  IServicesSupplementaireStyles
>(ServicesSupplementaireComponent, getStyles);
