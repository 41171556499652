import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IMyVehiclesSubComponentStyles {}

export interface IMyVehiclesStyles
  extends IAppBaseStyles<IMyVehiclesSubComponentStyles> {}

export interface IMyVehiclesStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getMyVehiclesClassNames = (
  styles?: IStyleFunctionOrObject<IMyVehiclesStyleProps, IMyVehiclesStyles>,
  props?: IMyVehiclesStyleProps
): IComponentBaseClassNames<
  IMyVehiclesStyleProps,
  IMyVehiclesStyles,
  IMyVehiclesSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IMyVehiclesStyleProps,
    IMyVehiclesStyles,
    IMyVehiclesSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IMyVehiclesStyleProps,
  IMyVehiclesStyles
> = (props: IMyVehiclesStyleProps): IMyVehiclesStyles => {
  return {
    root: ["app-MyVehicles", {}],
  };
};
