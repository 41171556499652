class CustomStringServices {
  ValidatePasswordRegex(value: string): boolean {
    var strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  }


  ValidateEmail(value: string): boolean {
    if (value.length > 250) {
      return false
    }
    var rgx = new RegExp(
      "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
    );
    const match = value.match(rgx);
    return match !== null && match.length > 0;
  }

  ValideMobile(value:string):boolean{
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?\d{3}\d{4}$/.test(value);
    
  }

  ValidatePostalCode(value: string): boolean {
    var match = /^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/.test(
      value
    );

    if (match) {
      if (
        (value.indexOf("-") !== -1 || value.indexOf(" ") !== -1) &&
        value.length === 7
      ) {
        return true;
      } else if (
        (value.indexOf("-") === -1 || value.indexOf(" ") === -1) &&
        value.length === 6
      ) {
        return true;
      }
    } else {
      return false;
    }
  }

  FormatDate = (date?: Date): string => {
    if (date !== undefined && date !== null) {
      let dateToFormat = new Date(date);

      let day =
        dateToFormat.getDate() < 10
          ? "0" + dateToFormat.getDate().toString()
          : dateToFormat.getDate().toString();
      let month =
        dateToFormat.getMonth() + 1 < 10
          ? "0" + (dateToFormat.getMonth() + 1).toString()
          : (dateToFormat.getMonth() + 1).toString();

      return !dateToFormat
        ? ""
        : dateToFormat.getFullYear() + "-" + month + "-" + day;
    }
  };

  FormatDateAvecHeure = (date?: Date, dateOnly?: Boolean): string => {
    if (date !== undefined && date !== null) {
      let dateToFormat = new Date(date);
      let minutes =
        dateToFormat.getMinutes() < 10
          ? dateToFormat.getMinutes() + "0"
          : dateToFormat.getMinutes();
      let day =
        dateToFormat.getDate() < 10
          ? "0" + dateToFormat.getDate().toString()
          : dateToFormat.getDate().toString();
      let month =
        dateToFormat.getMonth() + 1 < 10
          ? "0" + (dateToFormat.getMonth() + 1).toString()
          : (dateToFormat.getMonth() + 1).toString();

      if (!dateToFormat) {
        return "";
      } else {
        if (dateOnly === undefined || dateOnly === false) {
          return (
            dateToFormat.getHours().toString() +
            ":" +
            minutes +
            " " +
            dateToFormat.getFullYear() +
            "-" +
            month +
            "-" +
            day
          );
        } else {
          return dateToFormat.getFullYear() + "-" + month + "-" + day;
        }
      }
    }
  };

  StringReplace(value: string): string {
    return value === "" || value === null ? "\xa0" : value;
  }

  FormattedPrecisionValue = (value: string): string => {
    if (!value) {
      return "";
    }
    value = value.replace(",", ".").replace(/[^0-9.-]+/g, "");
    let regexStr = (0 ?? -1) >= 0 ? "^" : "^[-]?";
    regexStr += "([0-9]{0," + 2 + "})(\\.?)";
    regexStr += "2" ? "([0-9]{0," + "2" + "})" : "([0-9]*)";
    const regex = new RegExp(regexStr);
    const match = value.match(regex);
    return match === null || match.length === 0 ? "" : match[0];
  };

  ToProperCase = (valeur: string): string => {
    if (!valeur || !valeur.length) {
      return valeur;
    }

    var reset = true;
    var res = "";
    var i = 0;
    while (i < valeur.length) {
      var c = valeur.substring(i, i + 1);
      res += reset ? c.toUpperCase() : c.toLowerCase();
      var charCode = c.charCodeAt(0);
      reset = !(
        (charCode >= 65 && charCode <= 90) ||
        (charCode >= 97 && charCode <= 122) ||
        (charCode >= 192 && charCode <= 214) ||
        (charCode >= 216 && charCode <= 246) ||
        (charCode >= 248 && charCode <= 270)
      );
      i += 1;
    }
    return res;
  };
}

export default new CustomStringServices();
