import axios from "axios";
import Cookies from "universal-cookie";
import { IMiniPassenger } from "../Models/IMiniPassenger";
import { IPassenger } from "../Models/IPassenger";
import { UserInfo } from "../Models/IUserInfo";
import { apiUrl } from "./ApiService";
import CustomStringServices from "./CustomStringServices/CustomStringServices";

export class PassengerServices {
  public static async GetAllPassengers(): Promise<IMiniPassenger[]> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/clientpassager/get-clientpassagers`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
  public static async GetPassenger(id: string): Promise<IPassenger> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/clientpassager/get-clientpassager/${id}`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async AddPassenger(passenger: IPassenger): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/clientpassager/add-clientPassager/`,
        passenger,
        config
      );
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
  public static async EditPassenger(passenger: IPassenger): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/clientpassager/edit-clientPassager/`,
        passenger,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
  public static async Delete(id: number): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.delete<any>(
        `${apiUrl}/api/clientpassager/delete-clientpassager/${id}`,
        config
      );
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetCategorieAge(dateDeNaissance: Date): Promise<string> {
    let dateNow = new Date();
    let dateNowStr = CustomStringServices.FormatDate(dateNow);
    let ddnStr = CustomStringServices.FormatDate(dateDeNaissance);
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/clientpassager/get-categorieAge/${dateNowStr}/${ddnStr}`,
        config
      );

      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
