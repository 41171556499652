import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IParticularAttentionSubComponentStyles {}

export interface IParticularAttentionStyles
  extends IAppBaseStyles<IParticularAttentionSubComponentStyles> {}

export interface IParticularAttentionStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getParticularAttentionClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticularAttentionStyleProps,
    IParticularAttentionStyles
  >,
  props?: IParticularAttentionStyleProps
): IComponentBaseClassNames<
  IParticularAttentionStyleProps,
  IParticularAttentionStyles,
  IParticularAttentionSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IParticularAttentionStyleProps,
    IParticularAttentionStyles,
    IParticularAttentionSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IParticularAttentionStyleProps,
  IParticularAttentionStyles
> = (props: IParticularAttentionStyleProps): IParticularAttentionStyles => {
  return {
    root: ["app-ParticularAttention", {}],
  };
};
