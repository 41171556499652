import { Stack } from "@fluentui/react";
import * as React from "react";
import { getNvxCustomBorderRadiusStackClassNames } from "./NvxCustomBorderRadiusStack.styles";
import {
  INvxCustomBorderRadiusStackProps,
  INvxCustomBorderRadiusStackState,
} from "./NvxCustomBorderRadiusStack.types";

export class NvxCustomBorderRadiusStackComponent extends React.Component<
  INvxCustomBorderRadiusStackProps,
  INvxCustomBorderRadiusStackState
> {
  constructor(props: INvxCustomBorderRadiusStackProps) {
    super(props);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } =
      getNvxCustomBorderRadiusStackClassNames(styles!, {
        ...this.props,
        ...this.state,
      });

    const titleLenght = this.props.title.length * 10 + "px";
    return (
      <Stack
        style={{
          border: "1px solid black",
          borderRadius: this.props.isMobile ? 15 : 30,
          width: "95%",
          marginTop: "25px",
        }}
      >
        <h4
          style={{
            color: this.props.theme.palette.blueLight,
            maxWidth: titleLenght,
            marginTop: "-10px",
            marginLeft: this.props.isMobile ? "15px" : "30px",
            backgroundColor: "white",
            paddingRight: this.props.isMobile ? "15px" : "20px",
            paddingLeft: "10px",
            fontSize: "16px",
          }}
        >
          {this.props.title}
          {this.props.infoIcon}
        </h4>

        {this.props.render}
      </Stack>
    );
  }
}
