import {
  Checkbox,
  Label,
  MaskedTextField,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { getCommunicationInformationClassNames } from "./CommunicationInformation.styles";
import {
  ICommunicationInformationProps,
  ICommunicationInformationState,
} from "./CommunicationInformation.types";

export class CommunicationInformationComponent extends React.Component<
  ICommunicationInformationProps,
  ICommunicationInformationState
> {
  constructor(props: ICommunicationInformationProps) {
    super(props);
  }

  renderTelephone() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
          {this.props.mobile
            ? i18n.t("CommunicationInformation:TextFieldLabel:Telephone") + " "
            : ""}
          {this.props.telephone}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{ marginTop: "10px", width: this.props.mobile ? "" : "350px" }}
        >
          {this.props.mobile ? (
            <Label>
              {i18n.t("CommunicationInformation:TextFieldLabel:Telephone")}
            </Label>
          ) : (
            ""
          )}
          <TextField
            tabIndex={30}
            type="text"
            name="tel"
            autoComplete="tel"
            required={this.props.mobile}
            placeholder={this.props.mobile ? "Téléphone" : ""}
            value={this.props.telephone}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateTelephone(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderCellulaire() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
          {this.props.mobile
            ? i18n.t("CommunicationInformation:TextFieldLabel:Cellulaire") + " "
            : ""}
          {this.props.cellulaire === "(___) ___ - ____"
            ? ""
            : this.props.cellulaire}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{ marginTop: "10px", width: this.props.mobile ? "" : "350px" }}
        >
          {this.props.mobile ? (
            <Label>
              {i18n.t("CommunicationInformation:TextFieldLabel:Cellulaire")}
            </Label>
          ) : (
            ""
          )}
          <TextField
            tabIndex={31}
            placeholder={this.props.mobile ? "Cellulaire" : ""}            
            name="none"
            autoComplete="none"
            value={this.props.cellulaire}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateCellulaire(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderInfolettre() {
    if (!this.props.isPassenger) {
      return (
        <Stack
          style={{
            marginTop: this.props.isReadOnly ? "30px" : "10px",
            marginLeft: "20px",
          }}
          horizontalAlign={this.props.mobile ? "end" : "start"}
          tabIndex={33}
        >
          <Checkbox
            disabled={this.props.isReadOnly}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateInfolettre(v);
              }
            }}
            checked={this.props.infolettre}
          />
        </Stack>
      );
    }
  }

  renderMsgInfolettre() {
    if (!this.props.isPassenger) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {i18n.t("CommunicationInformation:EndMessage")}
        </Label>
      );
    }
  }

  renderEmail() {
    if (this.props.isPassenger) {
      return (
        <Stack
          style={{ marginTop: "10px", width: this.props.mobile ? "" : "350px" }}
        >
          <TextField
            validateOnFocusOut
            tabIndex={33}
            required={this.props.mobile}
            style={{ minWidth: "350px" }}
            value={this.props.courriel}
            placeholder={
              this.props.mobile
                ? i18n.t("CommunicationInformation:TextFieldLabel:Email")
                : ""
            }
            onChange={(e, v) => {
              if (v !== undefined && v.length < 250) {
                this.props.updateCourriel(v);
              }
            }}
          />
          {this.props.showMsgEmailError ? (
            <Label style={{ color: "red", marginLeft: "20px" }}>
              {i18n.t("LoginInformation:MsgError:EmailInvalid")}
            </Label>
          ) : (
            ""
          )}
        </Stack>
      );
    }
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } =
      getCommunicationInformationClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("CommunicationInformation:H_Title:Communication")}
        isMobile={true}
        render={
          <Stack style={{ width: "90%", margin: "15px" }}>
            {this.renderTelephone()}
            {this.renderCellulaire()}
            {this.renderEmail()}
            <Stack horizontal verticalAlign="center">
              {this.renderMsgInfolettre()}
              {this.renderInfolettre()}
            </Stack>
          </Stack>
        }
      />
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("CommunicationInformation:H_Title:Communication")}
        render={
          <Stack>
            <Stack
              horizontal
              style={{
                marginLeft: "20px",
                marginTop: "40px",
                marginBottom: "5px",
              }}
            >
              <Stack style={{ marginRight: "15px" }}>
                <Label
                  style={{ marginTop: "10px" }}
                  required={!this.props.isReadOnly}
                >
                  {i18n.t("CommunicationInformation:TextFieldLabel:Telephone")}
                </Label>
                <Label style={{ marginTop: "15px" }}>
                  {i18n.t("CommunicationInformation:TextFieldLabel:Cellulaire")}
                </Label>

                {this.props.isPassenger ? (
                  <Label
                    style={{ marginTop: "10px" }}
                    required={!this.props.isReadOnly}
                  >
                    {i18n.t("CommunicationInformation:TextFieldLabel:Email")}
                  </Label>
                ) : (
                  ""
                )}
              </Stack>

              <Stack>
                {this.renderTelephone()}
                {this.renderCellulaire()}
                {this.renderEmail()}
              </Stack>
            </Stack>

            <Stack
              horizontal
              verticalAlign="center"
              style={{
                marginLeft: "20px",
                marginBottom: "25px",
              }}
            >
              {this.renderMsgInfolettre()}
              {this.renderInfolettre()}
            </Stack>
          </Stack>
        }
      />
    );
  }
}
