import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IConfirmationPasswordSubComponentStyles {}

export interface IConfirmationPasswordStyles
  extends IAppBaseStyles<IConfirmationPasswordSubComponentStyles> {}

export interface IConfirmationPasswordStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getConfirmationPasswordClassNames = (
  styles?: IStyleFunctionOrObject<
    IConfirmationPasswordStyleProps,
    IConfirmationPasswordStyles
  >,
  props?: IConfirmationPasswordStyleProps
): IComponentBaseClassNames<
  IConfirmationPasswordStyleProps,
  IConfirmationPasswordStyles,
  IConfirmationPasswordSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IConfirmationPasswordStyleProps,
    IConfirmationPasswordStyles,
    IConfirmationPasswordSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IConfirmationPasswordStyleProps,
  IConfirmationPasswordStyles
> = (props: IConfirmationPasswordStyleProps): IConfirmationPasswordStyles => {
  return {
    root: ["app-ConfirmationPassword", {}],
  };
};
