import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IReservationPassengerVehicleSubComponentStyles {}

export interface IReservationPassengerVehicleStyles
  extends IAppBaseStyles<IReservationPassengerVehicleSubComponentStyles> {}

export interface IReservationPassengerVehicleStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getReservationPassengerVehicleClassNames = (
  styles?: IStyleFunctionOrObject<
    IReservationPassengerVehicleStyleProps,
    IReservationPassengerVehicleStyles
  >,
  props?: IReservationPassengerVehicleStyleProps
): IComponentBaseClassNames<
  IReservationPassengerVehicleStyleProps,
  IReservationPassengerVehicleStyles,
  IReservationPassengerVehicleSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IReservationPassengerVehicleStyleProps,
    IReservationPassengerVehicleStyles,
    IReservationPassengerVehicleSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IReservationPassengerVehicleStyleProps,
  IReservationPassengerVehicleStyles
> = (
  props: IReservationPassengerVehicleStyleProps
): IReservationPassengerVehicleStyles => {
  return {
    root: ["app-ReservationPassengerVehicle", {}],
  };
};
