import { Stack } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { AppService } from "../../Services/AppService";
import i18n from "../../Services/i18n";
import { getFooterClassNames } from "./Footer.styles";
import { IFooterProps, IFooterState } from "./Footer.types";

export class FooterComponent extends React.Component<
  IFooterProps,
  IFooterState
> {
  constructor(props: IFooterProps) {
    super(props);
    this.state = { 
      version: "",
      affficher: false
    };
  }

  async componentDidMount() {
    let v = await AppService.GetVersion();
    this.setState({ version: v.version, affficher : v.afficher });
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getFooterClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <Stack className={this.props.mobile ? classNames.footerMobile: classNames.footer}>
        <Stack horizontal horizontalAlign="center">
          
          
          <Link to="/contactus" style={{ cursor: "pointer", marginRight:"5px" }}>
            {i18n.t("app:footerContactUs")}{" "}
          </Link>         
          <a
            href={
              i18n.getLanguage() === "fr"
                ? "/pdf/RNI_Politique_confidentialité_francais.pdf"
                : "/pdf/RNI_Politique_confidentialité_anglais.pdf"
            }
            target={"_blank"}
          >{i18n.t("app:footerConfidentialite")}{" "}</a>

        </Stack>

        <Stack style={{ marginTop: "10px" }}>
          {i18n.t("app:footerDR")} 

          {this.state.affficher ? (
              "(" + this.state.version + ")"
          ) : (
            ""
          )}

           
        </Stack>
      </Stack>
    );
  }
}
