import { styled } from "@fluentui/react/lib/Utilities";
import { ILegalConditionsProps } from "./LegalConditions.types";
import {
  getStyles,
  ILegalConditionsStyleProps,
  ILegalConditionsStyles,
} from "./LegalConditions.styles";
import { LegalConditionsComponent } from "./LegalConditions.base";

/**
 * LegalConditions
 */
export const LegalConditions = styled<
  ILegalConditionsProps,
  ILegalConditionsStyleProps,
  ILegalConditionsStyles
>(LegalConditionsComponent, getStyles);
