import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { UserInfo } from "../../Models/IUserInfo";
import i18n from "../../Services/i18n";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IConfirmationPaymentProps,
  IConfirmationPaymentState,
} from "./ConfirmationPayment.types";

export class ConfirmationPaymentComponent extends React.Component<
  IConfirmationPaymentProps,
  IConfirmationPaymentState
> {
  private _cookies = new Cookies();

  private _userInfo = this._cookies.get("userInfo") as UserInfo;
  constructor(props: IConfirmationPaymentProps) {
    super(props);
  }
  
  componentDidMount() {   
    this.props.reloadItemAppHeader();
  }  

  render(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "20px", marginBottom: "50px" }}>
        <Stack
          horizontalAlign="center"
          style={{ width: this.props.mobile ? "95%" : "" }}
        >
          <Stack horizontal={!this.props.mobile} horizontalAlign="center">
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {i18n.t("Confirmation:Title:Merci")}
            </p>
            <p
              style={{
                fontSize: "26px",
                fontWeight: "bold",
                textAlign: "center",
                marginLeft: "20px",
                color: this.props.theme.palette.blueLight,
              }}
            >
              {this._userInfo.compteEntete.prenom.toUpperCase()}{" "}
              {this._userInfo.compteEntete.nom.toUpperCase()},
            </p>
          </Stack>
          <p
            style={{
              fontSize: "26px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {i18n.t("Confirmation:Title:Reservation")} <br />
            {i18n.t("Confirmation:Title:Reservation1")} <br />
            {i18n.t("Confirmation:Title:Reservation3")}
          </p>
        </Stack>

        <Stack
          style={{
            border: "1px solid black",
            borderRadius: this.props.mobile ? 15 : 50,
            width: "95%",
            marginTop: "25px",
            minHeight: "250px",
          }}
        >
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              maxWidth: this.props.mobile ? "80%" : "20%",
              marginTop: "-25px",
              marginLeft: this.props.mobile ? "15px" : "45px",
              backgroundColor: "white",
              paddingRight: this.props.mobile ? "15px" : "20px",
              paddingLeft: "10px",
              fontSize: "25px",
            }}
          >
            {i18n.t("Confirmation:Title:Resumer")}
          </Label>
          <Label
            style={{
              marginLeft: "20px",
              fontSize: "18px",
              textAlign: "center",
              marginRight: "10px",
            }}
          >
            {i18n.t("Confirmation:text:1")}
            <br /> <br />
            {i18n.t("Confirmation:text:2")}
            <br /> <br />
            {i18n.t("Confirmation:text:3")}
          </Label>
        </Stack>
      </Stack>
    );
  }
}
