import { styled } from "@fluentui/react/lib/Utilities";
import { IMyProfilProps } from "./MyProfil.types";
import {
  getStyles,
  IMyProfilStyleProps,
  IMyProfilStyles,
} from "./MyProfil.styles";
import { MyProfilComponent } from "./MyProfil.base";

/**
 * MyProfil
 */
export const MyProfil = styled<
  IMyProfilProps,
  IMyProfilStyleProps,
  IMyProfilStyles
>(MyProfilComponent, getStyles);
