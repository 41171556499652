import axios from "axios";
import { IAccount } from "../Models/IAccount";
import { CompteUtilisateur } from "../Models/ICompteUtilisateur";
import Cookies from "universal-cookie";
import { UserInfo } from "../Models/IUserInfo";
import { IDerniereNouvelleDto } from "../Models/IDerniereNouvelleDto";
import { apiUrl } from "./ApiService";

export class UserAccountServices {
  public static async AddUserAccount(userAccount: IAccount): Promise<number> {
    let result: any | undefined;
    try {
      const x = await axios.post<IAccount>(
        `${apiUrl}/api/compte/add-compte`,
        userAccount
      );
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async UserConnexion(userInfo: CompteUtilisateur): Promise<any> {
    let result: any | undefined;
    try {
      const x = await axios.post<any>(`${apiUrl}/api/auth/login`, userInfo);
      if (x.status < 299) {
        const cookies = new Cookies();

        cookies.set("userInfo", x.data);
      }
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }

    return result;
  }

  public static async UserLogout(): Promise<any> {
    let result: any | undefined;
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    try {
      const x = await axios.delete<any>(`${apiUrl}/api/auth/logout`, config);

      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }

    return result;
  }


  public static async ForgetPassword(email: string): Promise<number> {
    let result: any | undefined;
    try {
      const x = await axios.post<any>(`${apiUrl}/api/compte/changermotdepasse-compte`, {
        courriel: email,
      });
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async ConfirmationPassword(
    courriel: string,
    token: string,
    motDePasse: string
  ): Promise<any> {
    let result: any | undefined;
    try {
      const x = await axios.post<any>(
        `${apiUrl}/api/compte/confirmermotdepasse-compte`,
        {
          courriel,
          token,
          motDePasse,
        }
      );
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async Confirmation(
    courriel: string,
    token: string
  ): Promise<any> {
    let result: any | undefined;
    try {
      const x = await axios.post<any>(`${apiUrl}/api/compte/confirmer-compte`, {
        courriel,
        token,
      });
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetAccount(): Promise<IAccount> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/compte/get-compte`, config);
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async UpdateAccount(userAccount: IAccount): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.put<any>(
        `${apiUrl}/api/compte/edit-compte`,
        userAccount,
        config
      );
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetExistCompte(email: string): Promise<boolean> {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/compte/exist-compte/${email}`);
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetEstResident(): Promise<boolean> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;

    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.get<any>(`${apiUrl}/api/compte/get-estresident`, config);
      result = x.data.data;
    } catch (error) {
      result = (error as any)?.response?.status;
      result = result === 403 ? false : result;
    }
    return result;
  }

  public static async DeleteCompte(): Promise<number> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };

    let result: any | undefined;
    try {
      const x = await axios.delete<any>(`${apiUrl}/api/compte/delete-compte`, config);
      result = x.status;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }

  public static async GetLastNews(): Promise<IDerniereNouvelleDto> {
    const _cookies = new Cookies();
    const _userInfo = _cookies.get("userInfo") as UserInfo;
    const config = {
      headers: { Authorization: `Bearer ${_userInfo.token}` },
    };
    let result: any | undefined;

    try {
      const x = await axios.get(`${apiUrl}/api/compte/derniere-nouvelles`, config);
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
