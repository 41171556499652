import { styled } from "@fluentui/react/lib/Utilities";
import { INavBarProps } from "./NavBar.types";
import { getStyles, INavBarStyleProps, INavBarStyles } from "./NavBar.styles";
import { NavBarComponent } from "./NavBar.base";

/**
 * NavBar
 */
export const NavBar = styled<INavBarProps, INavBarStyleProps, INavBarStyles>(
  NavBarComponent,
  getStyles
);
