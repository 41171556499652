import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IPaymentSubComponentStyles {}

export interface IPaymentStyles
  extends IAppBaseStyles<IPaymentSubComponentStyles> {}

export interface IPaymentStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getPaymentClassNames = (
  styles?: IStyleFunctionOrObject<IPaymentStyleProps, IPaymentStyles>,
  props?: IPaymentStyleProps
): IComponentBaseClassNames<
  IPaymentStyleProps,
  IPaymentStyles,
  IPaymentSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPaymentStyleProps,
    IPaymentStyles,
    IPaymentSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IPaymentStyleProps, IPaymentStyles> = (
  props: IPaymentStyleProps
): IPaymentStyles => {
  return {
    root: ["app-Payment", {}],
  };
};
