import { styled } from "@fluentui/react/lib/Utilities";
import { IParticularAttentionProps } from "./ParticularAttention.types";
import {
  getStyles,
  IParticularAttentionStyleProps,
  IParticularAttentionStyles,
} from "./ParticularAttention.styles";
import { ParticularAttentionComponent } from "./ParticularAttention.base";

/**
 * ParticularAttention
 */
export const ParticularAttention = styled<
  IParticularAttentionProps,
  IParticularAttentionStyleProps,
  IParticularAttentionStyles
>(ParticularAttentionComponent, getStyles);
