import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IMyProfilSubComponentStyles {}

export interface IMyProfilStyles
  extends IAppBaseStyles<IMyProfilSubComponentStyles> {}

export interface IMyProfilStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getMyProfilClassNames = (
  styles?: IStyleFunctionOrObject<IMyProfilStyleProps, IMyProfilStyles>,
  props?: IMyProfilStyleProps
): IComponentBaseClassNames<
  IMyProfilStyleProps,
  IMyProfilStyles,
  IMyProfilSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IMyProfilStyleProps,
    IMyProfilStyles,
    IMyProfilSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IMyProfilStyleProps, IMyProfilStyles> = (
  props: IMyProfilStyleProps
): IMyProfilStyles => {
  return {
    root: ["app-MyProfil", {}],
  };
};
