import { DefaultButton, Label, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import { Navigate } from "react-router";
import { NvxCustomBorderRadiusStack } from "../../Components/NvxCustomBorderRadiusStack";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import { UserAccountServices } from "../../Services/UserAccountServices";
import {
  IConfirmationPasswordProps,
  IConfirmationPasswordState,
} from "./ConfirmationPassword.types";

export class ConfirmationPasswordComponent extends React.Component<
  IConfirmationPasswordProps,
  IConfirmationPasswordState
> {
  constructor(props: IConfirmationPasswordProps) {
    super(props);
    this.state = {
      isLoading: true,
      succes: false,
      fail: false,
      showRegexPW: false,
      showConfirmPWError: false,
      password: "",
      confirmPassword: "",
    };
  }

  showRegexPW() {
    if (this.state.showRegexPW) {
      return (
        <Stack>
          <Label style={{ color: "red" }}>
            {i18n.t("ConfirmationPassword:MotDePasseValidation")}
          </Label>
        </Stack>
      );
    }
  }

  showConfirmPWError() {
    if (this.state.showConfirmPWError) {
      return (
        <Label
          style={{
            color: "red",
          }}
        >
          {i18n.t("ConfirmationPassword:MotDePasseDifferent")}
        </Label>
      );
    }
  }

  render(): JSX.Element {

    this.setupLangue();

    if (this.state.succes) {
      return <Navigate to="/signin" />;
    } else {
      return (
        <Stack style={{ marginLeft: "20px" }}>
          <NvxCustomBorderRadiusStack
            {...this.props}
            title={i18n.t("ConfirmationPassword:Titre")}
            isMobile={false}
            render={
              <Stack style={{ marginLeft: "20px", marginBottom: "20px" }}>
                <Stack horizontal>
                  <Stack
                    style={{ minHeight: "80px", marginRight: "10px" }}
                    verticalAlign="space-between"
                  >
                    <Label>{i18n.t("ConfirmationPassword:MotDePasse")}</Label>
                    <Label>{i18n.t("ConfirmationPassword:ConfirmerMotDePasse")}</Label>
                  </Stack>
                  <Stack
                    style={{ minHeight: "80px" }}
                    verticalAlign="space-between"
                  >
                    <TextField
                      type="password"
                      canRevealPassword
                      onChange={(e, value) => {
                        if (value !== undefined) {
                          let showErrorMsg: boolean;
                          if (
                            CustomStringServices.ValidatePasswordRegex(value)
                          ) {
                            showErrorMsg = false;
                          } else {
                            showErrorMsg = true;
                          }
                          this.setState({
                            password: value,
                            showRegexPW: showErrorMsg,
                          });
                        }
                      }}
                    />

                    <TextField
                      type="password"
                      canRevealPassword
                      onChange={(e, value) => {
                        let showErrorMsg: boolean;
                        if (value !== undefined) {
                          if (this.state.password !== value) {
                            showErrorMsg = true;
                          } else {
                            showErrorMsg = false;
                          }
                          this.setState({
                            confirmPassword: value,
                            showConfirmPWError: showErrorMsg,
                          });
                        }
                      }}
                    />
                  </Stack>
                </Stack>

                <Stack style={{ minHeight: "75px" }}>
                  {this.showConfirmPWError()}
                  {this.showRegexPW()}
                </Stack>
                <DefaultButton
                  disabled={!this.canBeSave()}
                  style={{
                    color: "white",
                    backgroundColor: this.canBeSave()
                      ? this.props.theme.palette.blueDark
                      : this.props.theme.palette.neutralLight,
                    borderRadius: 10,
                    height: "50px",
                    width: "250px",
                  }}
                  onClick={() => {
                    this.confirmation();
                  }}
                >
                  {i18n.t("ConfirmationPassword:Sauvegarder")}
                </DefaultButton>
              </Stack>
            }
          />
        </Stack>
      );
    }
  }

  canBeSave(): boolean {
    return (
      !this.state.showConfirmPWError &&
      !this.state.showRegexPW &&
      this.state.confirmPassword !== "" &&
      this.state.password !== ""
    );
  }

  async setupLangue() {
    let urlSplit = window.location.href.split("?")[1].split("&");
    let email = urlSplit[0].split("=")[1];
    let token = urlSplit[1].split("=")[1];
    let lang = urlSplit[2].split("=")[1];
    i18n.setLanguage(lang);
  }


  async confirmation() {
    let urlSplit = window.location.href.split("?")[1].split("&");
    let email = urlSplit[0].split("=")[1];
    let token = urlSplit[1].split("=")[1];
    let lang = urlSplit[2].split("=")[1];
    i18n.setLanguage(lang);

    let status = await UserAccountServices.ConfirmationPassword(
      email,
      token,
      this.state.password
    );

    switch (status) {
      case 200:
        this.setState({ isLoading: false, succes: true });
        break;

      default:
        break;
    }
  }
}
