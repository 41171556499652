import { IStyleSet } from "@fluentui/react";
import {
  classNamesFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react/lib/Utilities";
import { IComponentBaseClassNames } from "./IComponentBaseClassNames";

/**
 * Component Styles Loader
 *
 * Helps with assembling and merging styles into css
 */
export class ComponentStylesLoader {
  /**
   * Get component class names of component styles loader
   */
  public static getComponentClassNames = <
    TStyleProps,
    TStyleSet extends IStyleSet<TStyleSet>,
    TSubComponentStyles
  >(
    styles?: IStyleFunctionOrObject<TStyleProps, TStyleSet>,
    props?: TStyleProps
  ): IComponentBaseClassNames<TStyleProps, TStyleSet, TSubComponentStyles> => {
    const getClassNames = classNamesFunction<TStyleProps, TStyleSet>();
    const classNames = getClassNames(styles!, props);
    return {
      classNames: classNames,
      subComponentStyles:
        classNames.subComponentStyles as unknown as TSubComponentStyles,
    };
  };
}
