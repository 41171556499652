import React from "react";
import ReactDOM from "react-dom";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App/App";
import { IAppProps } from "./App/App.types";
import { HttpClient } from "./ComponentBase/HttpClient";
import { getAppCustomizations, IAppCustomizations } from "./theme";
import { initializeIcons } from "@fluentui/react";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

initializeIcons();

const { theme, tokens }: IAppCustomizations = getAppCustomizations();
const props: IAppProps = {
  theme: theme,
  tokens: tokens,
  mobile: false,
  tablet: false,
  http: HttpClient.create(),
  language: "",
};

ReactDOM.render(
  <React.Suspense fallback={() => <div></div>}>
    <App {...props} />
  </React.Suspense>,

  rootElement
);
