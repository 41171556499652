import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import { IContactUsProps, IContactUsState } from "./ContactUs.types";

export class ContactUsComponent extends React.Component<
  IContactUsProps,
  IContactUsState
> {
  constructor(props: IContactUsProps) {
    super(props);
    this.state = {
      isTerminaux: true,
    };
  }

  render(): JSX.Element {
    return (
      <Stack
        horizontal={!this.props.mobile}
        horizontalAlign="space-between"
        style={{
          marginLeft: this.props.mobile ? "10px" : "25px",
          marginRight: this.props.mobile ? "10px" : "25px",
          height: this.props.mobile ? "100%" : "60vh",
        }}
      >
        <Stack
          style={{
            border: "2px solid black",
            borderRadius: 25,
            width: this.props.mobile ? "100%" : "30%",
            height: this.props.mobile ? "100%" : "80vh",
            marginTop: "25px",
          }}
        >
          <Label
            style={{
              height: "10%",
              color: this.props.theme.palette.blueDark,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: 25,
              borderBottom: "2px solid black",
            }}
          >
            {i18n.t("ContactUs:Label1:Terminaux")}
          </Label>

          <Stack style={{ marginLeft: "20px" }}>
            <h2 style={{ color: this.props.theme.palette.blueLight }}>
              {i18n.t("ContactUs:Label1:Rimouski")}
            </h2>
            <Label style={{ fontWeight: "bold" }}>
              {i18n.t("ContactUs:Label1:RNInc")}
              <br />
              {i18n.t("ContactUs:Label1:Adress")}
              <br />
              {i18n.t("ContactUs:Label1:ville")} <br />
              {i18n.t("ContactUs:Label1:CodePostal")} <br />
            </Label>
            <Stack horizontal>
              <Label style={{ fontWeight: "bold" }}>
                {i18n.t("ContactUs:Label1:PhoneLabel")} &nbsp;
              </Label>
              <Label style={{ fontWeight: "bold" }}>
                {i18n.t("ContactUs:Label1:Phone1")} <br />{" "}
                {i18n.t("ContactUs:Label1:Phone2")}
              </Label>
            </Stack>
            <Label style={{ fontWeight: "bold" }}>
              {i18n.t("ContactUs:Label1:Fax")}
              <br />
            </Label>
            <Label style={{ fontWeight: "bold" }}>
              {i18n.t("ContactUs:Label1:OpenDay")}
              <br />
              {i18n.t("ContactUs:Label1:OpenHours")} <br />
              {i18n.t("ContactUs:Label1:CloseBetween")}
            </Label>
          </Stack>

          <Stack style={{ marginLeft: "20px" }}>
            <h2 style={{ color: this.props.theme.palette.blueLight }}>
              {i18n.t("ContactUs:Label2:SeptIle")}
            </h2>
            <Label style={{ fontWeight: "bold" }}>
              {i18n.t("ContactUs:Label2:RNInc")}
              <br />
              {i18n.t("ContactUs:Label2:Adress")} <br />
              {i18n.t("ContactUs:Label2:ville")} <br />
              {i18n.t("ContactUs:Label2:CodePostal")} <br />
            </Label>

            <Stack horizontal>
              <Label style={{ fontWeight: "bold" }}>
                {i18n.t("ContactUs:Label1:PhoneLabel")} &nbsp;
              </Label>
              <Label style={{ fontWeight: "bold" }}>
                {i18n.t("ContactUs:Label2:Phone1")}
              </Label>
            </Stack>

            <Label style={{ fontWeight: "bold" }}>
              {i18n.t("ContactUs:Label1:OpenDay")}
              <br />
              {i18n.t("ContactUs:Label1:OpenHours")} <br />
              {i18n.t("ContactUs:Label1:CloseBetween")}
            </Label>
          </Stack>
        </Stack>

        <Stack
          verticalAlign="center"
          style={{
            width: this.props.mobile ? "100%" : "100vh",
            marginBottom: "100px",
          }}
          horizontalAlign="center"
        >
          <h1
            style={{ color: this.props.theme.palette.blueLight, fontSize: 45 }}
          >
            {i18n.t("ContactUs:h1:ContactUs")}
          </h1>
          <Stack>
            <Label style={{ fontWeight: "bold", fontSize: "16px" }}>
              {i18n.t("ContactUs:Label:Opinion")}
            </Label>
            <Label>
              {i18n.t("ContactUs:Label:1")} <br />
              {i18n.t("ContactUs:Label:2")}
              <br />
              {i18n.t("ContactUs:Label:3")}
            </Label>
            <a href="https://relaisnordik.com/contact">
              {i18n.t("ContactUs:link:site")}
            </a>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}
