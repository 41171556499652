import { styled } from "@fluentui/react/lib/Utilities";
import { IRouteCalculatorProps } from "./RouteCalculator.types";
import {
  getStyles,
  IRouteCalculatorStyleProps,
  IRouteCalculatorStyles,
} from "./RouteCalculator.styles";
import { RouteCalculatorComponent } from "./RouteCalculator.base";

/**
 * RouteCalculator
 */
export const RouteCalculator = styled<
  IRouteCalculatorProps,
  IRouteCalculatorStyleProps,
  IRouteCalculatorStyles
>(RouteCalculatorComponent, getStyles);
