import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import CurrenceService from "../../Services/CurrencyService/CurrencyService";
import { ReservationServices } from "../../Services/ReservationServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { ITicketingProps, ITicketingState } from "./Ticketing.types";
import CurrencyService from "../../Services/CurrencyService/CurrencyService";
import { StylingServices } from "../../Services/StylingServices";

export class TicketingComponent extends React.Component<
  ITicketingProps,
  ITicketingState
> {
  constructor(props: ITicketingProps) {
    super(props);
    this.state = {
      sommaireReservation: {
        voyage: "",
        troncon: "",
        dateEmbarquement: "",
        coutsPassagers: [],
        coutsVehicules: [],
        coutsServices: [],
        totaux: undefined,
      },
      isLoading: true,
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("Ticketing", true);
    
    await this.props.getPlaceDispo()

    let passagerIds = this.props.miniPassengerSelected.map((x) => {
      return x.id.toString();
    });

    let rs = await ReservationServices.GetSommaireReservation(
      parseInt(this.props.portOrigine.id.toString()),
      parseInt(this.props.portDestination.id.toString()),
      this.props.dateDeDepart.id,
      this.props.estAllerRetour,
      this.props.servicesSuppCode,
      passagerIds,
      this.props.vehicleSelected,
      this.props.tutor
    );

    this.setState({ sommaireReservation: rs, isLoading: false });
    this.props.isLoading();
    LoadingServices.setLoading("Ticketing", false);
  }

  renderDesktop(): JSX.Element {
    return (
      <Stack>
        {this.renderSomaire()}
        {this.renderCout()}
      </Stack>
    );
  }

  render(): JSX.Element {
    if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return (
        <>
          {this.renderPlaceDispoInfo()}
          {this.renderNoPlaceRemain()}
          {this.props.mobile ? this.renderMobile() : this.renderDesktop()}
        </>
      )
    }
  }

  renderSomaire(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("Somaire:Title:Sommaire")}
        render={
          <Stack style={{ marginLeft: "20px", marginBottom: "20px" }}>
            <Stack horizontal={!this.props.mobile}>
              <Stack>
                <Label>
                  {i18n.t("Somaire:Label:Voyage")}
                  {this.state.sommaireReservation.voyage}
                </Label>
              </Stack>

              <Stack>
                <Label
                  style={{
                    marginLeft: this.props.mobile ? "" : "20px",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("Somaire:Label:Troncon")}
                  {this.state.sommaireReservation.troncon}
                </Label>
              </Stack>
            </Stack>
            <Stack>
              <Label>
                {i18n.t("Somaire:Label:DateDembarquement")}
                {CustomStringServices.FormatDate(
                  new Date(this.state.sommaireReservation.dateEmbarquement)
                )}
              </Label>
            </Stack>

            {this.state.sommaireReservation.coutsPassagers.length > 0 ? (
              <Stack
                horizontal={!this.props.mobile}
                style={{ marginLeft: this.props.mobile ? "" : "20px" }}
              >
                <Stack>
                  <Label
                    style={{
                      fontWeight: "bold",
                      minWidth: !this.props.mobile ? "175px" : "",
                    }}
                  >
                    {i18n.t("Somaire:Label:CoutPassage")}
                  </Label>
                </Stack>

                <Stack
                  style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                >
                  {this.state.sommaireReservation.coutsPassagers.map(
                    (x, index) => {
                      return this.renderInfo(
                        x.description,
                        x.sousTotal,
                        index,
                        x.passager
                      );
                    }
                  )}
                </Stack>
              </Stack>
            ) : (
              ""
            )}

            {this.state.sommaireReservation.coutsVehicules.length > 0 ? (
              <Stack
                horizontal={!this.props.mobile}
                style={{ marginLeft: this.props.mobile ? "" : "20px" }}
              >
                <Stack>
                  <Label
                    style={{
                      fontWeight: "bold",
                      minWidth: !this.props.mobile ? "175px" : "",
                    }}
                  >
                    {i18n.t("Somaire:Label:CoutVehicle")}
                  </Label>
                </Stack>
                <Stack
                  style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                >
                  {this.state.sommaireReservation.coutsVehicules.map(
                    (x, index) => {
                      return this.renderInfo(
                        x.code,
                        x.sousTotal,
                        index,
                        x.vehicule
                      );
                    }
                  )}
                </Stack>
              </Stack>
            ) : (
              ""
            )}

            {this.state.sommaireReservation.coutsServices.length > 0 ? (
              <Stack
                horizontal={!this.props.mobile}
                style={{ marginLeft: this.props.mobile ? "" : "20px" }}
              >
                <Stack>
                  <Label
                    style={{
                      fontWeight: "bold",
                      minWidth: !this.props.mobile ? "175px" : "",
                    }}
                  >
                    {i18n.t("Somaire:Label:CoutSS")}
                  </Label>
                </Stack>
                <Stack style={{ marginLeft: "10px" }}>
                  {this.state.sommaireReservation.coutsServices.map(
                    (x, index) => {
                      return this.renderInfo(x.service, x.sousTotal, index);
                    }
                  )}
                </Stack>
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        }
      />
    );
  }

  renderMobile(): JSX.Element {
    return (
      <Stack>
        {this.renderSomaire()}
        {this.renderCout()}
      </Stack>
    );
  }

  renderInfo(
    nom: string,
    sousTotal: number,
    index: number,
    description?: string
  ) {
    let sousTotalString = CurrenceService.ToDollars(sousTotal);
    
    return (
      <Stack
        horizontal={!this.props.mobile}
        key={index}
        style={{
          borderBottom: this.props.mobile ? "solid" : "",
          width: this.props.mobile ? "75%" : "100%",
        }}
      >
        {description !== null ? <Label>{description}</Label> : ""}
        <Label style={{ marginLeft: this.props.mobile ? "" : "10px" }}>
          {nom} {this.props.mobile ? <p>{sousTotalString}</p> : ""}
        </Label>
        {!this.props.mobile ? (
          <Label style={{ marginLeft: "10px" }}>{sousTotalString}</Label>
        ) : (
          ""
        )}
      </Stack>
    );
  }

  renderPlaceDispoInfo(){
    let color = "red";
    if (this.props.informationSurreservation !== null) {
      color = StylingServices.GetGradationColor(this.props.informationSurreservation.nombrePlaceUtilise, this.props.informationSurreservation.nombrePlaceMaximum)
    }

    if (this.props.vehicleSelected.length>0 && this.props.informationSurreservation !== null) {
      return (<Stack style={{ marginTop: "15px" }}>
        <Label style={{ color: color }}>                
          {i18n.t("Message:VehiculePlaceDispo1")}
          {this.props.informationSurreservation.nombrePlaceDisponible}
          {i18n.t("Message:VehiculePlaceDispo2")}
        </Label>        
      </Stack>)
    } else {
      return <></>
    }    
  }

  renderNoPlaceRemain(){
    if (
        this.props.vehicleSelected.length>0 &&
        this.props.informationSurreservation.nombrePlaceDisponible < this.props.vehicleSelected.length      
      ) {
      return (<Stack style={{ marginTop: "15px" }}>
      <Label style={{ color: "red" }}>
        {i18n.t("RPV:Message:TooManySelectedVehicle1")}
        {this.props.informationSurreservation.nombrePlaceDisponible}
        {i18n.t("RPV:Message:TooManySelectedVehicle2")}
      </Label>
    </Stack>)
    } else {
      return <></>
    }    
  }

  renderCout() {
    let coutPassagerTotal = 0;
    this.state.sommaireReservation.coutsPassagers.forEach((x) => {
      coutPassagerTotal = coutPassagerTotal + x.sousTotal;
    });
    let coutVehiculeTotal = 0;
    this.state.sommaireReservation.coutsVehicules.forEach((x) => {
      coutVehiculeTotal = coutVehiculeTotal + x.sousTotal;
    });

    let coutServiceTotal = 0;
    this.state.sommaireReservation.coutsServices.forEach((x) => {
      coutServiceTotal = coutServiceTotal + x.sousTotal;
    });

    return (
      <Stack
        style={{
          border: "1px solid black",
          borderRadius: 15,
          width: "95%",
          marginTop: "45px",
          backgroundColor: this.props.theme.palette.blueDark,
        }}
      >
        <h2
          style={{
            color: this.props.theme.palette.white,
            position: "absolute",
            marginTop: "5px",

            paddingRight: "50px",
            paddingLeft: "10px",
          }}
        >
          {i18n.t("Somaire:Label:SommaireDesCouts")}
        </h2>

        <Stack
          style={{
            marginLeft: "20px",
            marginTop: "40px",
            marginRight: "150px",
            marginBottom: "20px",
          }}
          horizontal={!this.props.mobile}
          horizontalAlign="space-between"
        >
          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:Passager")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {CurrenceService.ToDollars(coutPassagerTotal)}
            </Label>
          </Stack>

          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:Vehicules")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {CurrencyService.ToDollars(coutVehiculeTotal)}
            </Label>
          </Stack>

          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:Services")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {CurrencyService.ToDollars(coutServiceTotal)}
            </Label>
          </Stack>

          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:Total")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {CurrencyService.ToDollars(this.state.sommaireReservation.totaux.sousTotal)}
            </Label>
          </Stack>

          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:Tps")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
               { CurrenceService.ToDollars(this.state.sommaireReservation.totaux.tps)}
            </Label>
          </Stack>

          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:Tvq")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
               {CurrencyService.ToDollars(this.state.sommaireReservation.totaux.tvq)}
            </Label>
          </Stack>

          <Stack
            horizontalAlign={this.props.mobile ? "start" : "center"}
            horizontal={this.props.mobile}
          >
            <Label
              style={{
                color: "white",
                minWidth: this.props.mobile ? "150px" : "",
              }}
            >
              {i18n.t("Somaire:Label:MontantAPayer")}
            </Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {CurrencyService.ToDollars(this.state.sommaireReservation.totaux.total)}
            </Label>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}
