import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IMyPassengersSubComponentStyles {}

export interface IMyPassengersStyles
  extends IAppBaseStyles<IMyPassengersSubComponentStyles> {}

export interface IMyPassengersStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getMyPassengersClassNames = (
  styles?: IStyleFunctionOrObject<IMyPassengersStyleProps, IMyPassengersStyles>,
  props?: IMyPassengersStyleProps
): IComponentBaseClassNames<
  IMyPassengersStyleProps,
  IMyPassengersStyles,
  IMyPassengersSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IMyPassengersStyleProps,
    IMyPassengersStyles,
    IMyPassengersSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IMyPassengersStyleProps,
  IMyPassengersStyles
> = (props: IMyPassengersStyleProps): IMyPassengersStyles => {
  return {
    root: ["app-MyPassengers", {}],
  };
};
