import { DefaultButton, IComboBoxOption, Stack } from "@fluentui/react";
import * as React from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import { IAdresse } from "../../Models/IAdresse";
import { IParticulariteBase } from "../../Models/IParticulariteBase";
import { IPassenger } from "../../Models/IPassenger";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { PassengerServices } from "../../Services/PassengerServices";
import { ProvinceServices } from "../../Services/ProvinceServices";
import { UserAccountServices } from "../../Services/UserAccountServices";
import { ValeurServices } from "../../Services/ValeurServices";
import { AdressInformation } from "../AdressInformation";
import { BasicInformation } from "../BasicInformation";
import { CommunicationInformation } from "../CommunicationInformation";
import { ParticularAttention } from "../ParticularAttention";
import { getPassengerClassNames } from "./Passenger.styles";
import { IPassengerProps, IPassengerState } from "./Passenger.types";

export class PassengerComponent extends React.Component<
  IPassengerProps,
  IPassengerState
> {
  constructor(props: IPassengerProps) {
    super(props);
    this.state = {
      isLoading: true,
      provinces: [],
      titleOptions: [],
      clientIsResident: true,
      passenger: {
        id: 0,
        informationPassager: {
          prenom: "",
          nom: "",
          titre: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMister"),
          dateDeNaissance: new Date(),
          genre: 0,
          categorieAge: "",
        },
        adresse: {
          villeResidentCode: "",
          memeAdresseQueClient: false,
          numeroCivique: "",
          rue: "",
          appartement: "",
          casierPostal: "",
          ville: "",
          provinceId: 0,
          codePostal: "",
        },
        attentionParticuliere: {
          estMobliliteReduite: false,
          conditions: [],
          particulariteAlimentaires: [],
          contactUrgence: {
            identification: "",
            relation: "",
            telephone1: "",
            telephone2: "",
          },
          autres: "",
        },
        communication: {
          telephone: "",
          cellulaire: "",
          infolettre: false,
          courriel: "",
        },
      },
      redirect: false,
      fail: false,
      isEdit: false,
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("getPassengers", true);
    let provinceId: number = 0;

    let isResident = await UserAccountServices.GetEstResident();

    if (isResident) {
      let p = await ProvinceServices.GetProvinces();
      provinceId = p.filter((x) => x.nom === "Quebec" || x.nom === "Québec")[0]
        .id;
    }

    let p = await ProvinceServices.GetProvinces();
    let optionProvinces: IComboBoxOption[] = [];
    p.forEach((x) => {
      optionProvinces.push({ key: x.id, text: x.nom });
    });
    this.setState({ provinces: optionProvinces });

    const titleOptions: IComboBoxOption[] = [
      {
        key: "M",
        text: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMister"),
      },
      {
        key: "MME",
        text: i18n.t("BasicInformation:ChoiceGroupe:AbbreviationMiss"),
      },
    ];
    this.setState({ titleOptions });

    if (window.location.href.includes("=")) {
      let urlSplit = window.location.href.split("/");
      let passengerId = urlSplit[4].split("=")[1];
      let p = await PassengerServices.GetPassenger(passengerId);

      if (p !== null) {
        let resultDate = await PassengerServices.GetCategorieAge(
          p.informationPassager.dateDeNaissance
        );

        let passenger: IPassenger = {
          id: p.id,
          informationPassager: {
            prenom: p.informationPassager.prenom
              ? p.informationPassager.prenom
              : "",
            nom: p.informationPassager.nom ? p.informationPassager.nom : "",
            titre: p.informationPassager.titre
              ? p.informationPassager.titre
              : "",
            dateDeNaissance: p.informationPassager.dateDeNaissance
              ? p.informationPassager.dateDeNaissance
              : new Date(),
            genre: p.informationPassager.genre
              ? p.informationPassager.genre
              : 0,
            categorieAge: resultDate,
          },
          adresse: {
            villeResidentCode: p.adresse.villeResidentCode,
            memeAdresseQueClient: false,
            numeroCivique: p.adresse.numeroCivique
              ? p.adresse.numeroCivique
              : "",
            rue: p.adresse.rue ? p.adresse.rue : "",
            appartement: p.adresse.appartement ? p.adresse.appartement : "",
            casierPostal: p.adresse.casierPostal ? p.adresse.casierPostal : "",
            ville: p.adresse.ville ? p.adresse.ville : "",
            provinceId: isResident
              ? provinceId
              : p.adresse.provinceId
              ? p.adresse.provinceId
              : 0,
            codePostal: p.adresse.codePostal ? p.adresse.codePostal : "",
          },
          attentionParticuliere: {
            estMobliliteReduite: p.attentionParticuliere.estMobliliteReduite,
            conditions: p.attentionParticuliere.conditions,
            particulariteAlimentaires:
              p.attentionParticuliere.particulariteAlimentaires,
            contactUrgence: {
              identification:
                p.attentionParticuliere.contactUrgence.identification,
              relation: p.attentionParticuliere.contactUrgence.relation,
              telephone1: p.attentionParticuliere.contactUrgence.telephone1,
              telephone2: p.attentionParticuliere.contactUrgence.telephone2,
            },
            autres: p.attentionParticuliere.autres,
          },
          communication: {
            telephone: p.communication.telephone,
            cellulaire: p.communication.cellulaire,
            infolettre: p.communication.infolettre,
            courriel: p.communication.courriel,
          },
        };

        this.setState({
          passenger: passenger,
          isEdit: true,
          clientIsResident: isResident,
          isLoading: false,
        });
      }
    } else {
      let v = await ValeurServices.GetAttentionParticuiliere();
      let particulariteAlimentaire: IParticulariteBase[] = [];
      let conditions: IParticulariteBase[] = [];

      v.forEach((x) => {
        switch (x.domaine) {
          case "CD_PARTICULARITE_ALIMENTAIRE":
            particulariteAlimentaire.push({
              code: x.code,
              valeur: false,
              domaine: x.domaine,
              description: x.description,
            });
            break;
          case "CD_CONDITION_PARTICULIERE":
            conditions.push({
              code: x.code,
              valeur: false,
              domaine: x.domaine,
              description: x.description,
            });
            break;
          default:
            break;
        }
      });

      this.state.passenger.attentionParticuliere.conditions = conditions;
      this.state.passenger.attentionParticuliere.particulariteAlimentaires =
        particulariteAlimentaire;
      this.setState({
        ...this.state,
        clientIsResident: isResident,
        isLoading: false,
      });
    }
    LoadingServices.setLoading("getPassengers", false);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getPassengerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    if (this.state.redirect) {
      return <Navigate to="/mypassengers" />;
    } else if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return (
        <Stack style={{ marginBottom: "100px", marginLeft: "15px" }}>
          <h1 style={{ color: this.props.theme.palette.blueLight }}>
            {i18n.t("Passenger:title:h1-1")}
          </h1>
          <BasicInformation
            {...this.props}
            titleOptions={this.state.titleOptions}
            isNew={this.state.passenger.id === 0}
            isClient={false}
            isResident={this.state.clientIsResident}
            isReadOnly={false}
            updateTitre={(value) => {
              this.state.passenger.informationPassager.titre = value;
              this.setState({ ...this.state });
            }}
            updatePrenom={(value) => {
              this.state.passenger.informationPassager.prenom =
                CustomStringServices.ToProperCase(value);
              this.setState({ ...this.state });
            }}
            updateNom={(value) => {
              this.state.passenger.informationPassager.nom = value;
              this.setState({ ...this.state });
            }}
            updateDateDeNaissance={async (value) => {
              let toDay = new Date(Date.now());
              let passenger = this.state.passenger;
              passenger.informationPassager.dateDeNaissance =
                toDay.getTime() > value.getTime() ? value : toDay;
              let catAge = await this.GetCategorieAge(value);
              passenger.informationPassager.categorieAge = catAge;
              this.setState({ passenger: passenger });
            }}
            titre={
              this.state.passenger.informationPassager.titre
                ? this.state.passenger.informationPassager.titre
                : ""
            }
            prenom={this.state.passenger.informationPassager.prenom}
            nom={this.state.passenger.informationPassager.nom}
            dateDeNaissance={
              this.state.passenger.informationPassager.dateDeNaissance
            }
            categorieAge={this.state.passenger.informationPassager.categorieAge}
            genre={this.state.passenger.informationPassager.genre}
            updateGenre={(value) => {
              this.state.passenger.informationPassager.genre = value;
              this.setState({ ...this.state });
            }}
          />
          <AdressInformation
            {...this.props}
            provinces={this.state.provinces}
            villeResidentCode={this.state.passenger.adresse.villeResidentCode}
            isResident={this.state.clientIsResident}
            isPassenger={true}
            isReadOnly={false}
            updateNumeroCivique={(value) => {
              this.state.passenger.adresse.numeroCivique = value;
              this.setState({ ...this.state });
            }}
            updateRue={(value) => {
              this.state.passenger.adresse.rue = value;
              this.setState({ ...this.state });
            }}
            updateAppartement={(value) => {
              this.state.passenger.adresse.appartement = value;
              this.setState({ ...this.state });
            }}
            updateVille={(value) => {
              this.state.passenger.adresse.villeResidentCode = value;
              this.state.passenger.adresse.ville = value;
              this.setState({ ...this.state });
            }}
            updateCodePostal={(value) => {
              this.state.passenger.adresse.codePostal = value;
              this.setState({ ...this.state });
            }}
            updateCasierPostal={(value) => {
              this.state.passenger.adresse.casierPostal = value;
              this.setState({ ...this.state });
            }}
            updateProvince={(value) => {
              this.state.passenger.adresse.provinceId = parseFloat(value);
              this.setState({ ...this.state });
            }}
            updateMemeAdresseClient={(value) => {
              this.setAdressClient(value);
            }}
            numeroCivique={
              this.state.passenger.adresse.numeroCivique
                ? this.state.passenger.adresse.numeroCivique
                : ""
            }
            rue={
              this.state.passenger.adresse.rue
                ? this.state.passenger.adresse.rue
                : ""
            }
            appartement={
              this.state.passenger.adresse.appartement
                ? this.state.passenger.adresse.appartement
                : ""
            }
            ville={
              this.state.passenger.adresse.ville
                ? this.state.passenger.adresse.ville
                : ""
            }
            codePostal={
              this.state.passenger.adresse.codePostal
                ? this.state.passenger.adresse.codePostal
                : ""
            }
            casierPostal={
              this.state.passenger.adresse.casierPostal
                ? this.state.passenger.adresse.casierPostal
                : ""
            }
            provinceId={
              this.state.passenger.adresse.provinceId
                ? this.state.passenger.adresse.provinceId
                : null
            }
          />

          <CommunicationInformation
            {...this.props}
            isReadOnly={false}
            isPassenger={true}
            updateTelephone={(value) => {
              this.state.passenger.communication.telephone = value;
              this.setState({ ...this.state });
            }}
            updateCellulaire={(value) => {
              this.state.passenger.communication.cellulaire = value;
              this.setState({ ...this.state });
            }}
            updateCourriel={async (value) => {
              let showErrorMsg: boolean;
              this.state.passenger.communication.courriel = value;
              if (CustomStringServices.ValidateEmail(value)) {
                showErrorMsg = false;
              } else {
                showErrorMsg = true;
              }

              this.setState({ ...this.state, showMsgEmailError: showErrorMsg });
            }}
            telephone={this.state.passenger.communication?.telephone}
            cellulaire={this.state.passenger.communication?.cellulaire}
            courriel={this.state.passenger.communication?.courriel}
            showMsgEmailError={this.state.showMsgEmailError}
          />
          <ParticularAttention
            {...this.props}
            conditions={this.state.passenger.attentionParticuliere.conditions}
            particulariteAlimentaires={
              this.state.passenger.attentionParticuliere
                .particulariteAlimentaires
            }
            contactUrgence={
              this.state.passenger.attentionParticuliere.contactUrgence
            }
            autre={this.state.passenger.attentionParticuliere.autres}
            updateConditionValue={(c, v) => {
              this.state.passenger.attentionParticuliere.conditions.filter(
                (x) => x.code === c
              )[0].valeur = v;
              this.setState({ ...this.state });
            }}
            updateParticulariteAlimentairesValue={(c, v) => {
              this.state.passenger.attentionParticuliere.particulariteAlimentaires.filter(
                (x) => x.code === c
              )[0].valeur = v;
              this.setState({ ...this.state });
            }}
            updateIdentification={(value) => {
              this.state.passenger.attentionParticuliere.contactUrgence.identification =
                value;
              this.setState({ ...this.state });
            }}
            updateRelation={(value) => {
              this.state.passenger.attentionParticuliere.contactUrgence.relation =
                value;
              this.setState({ ...this.state });
            }}
            updateTelephone1={(value) => {
              this.state.passenger.attentionParticuliere.contactUrgence.telephone1 =
                value;
              this.setState({ ...this.state });
            }}
            updateTelephone2={(value) => {
              this.state.passenger.attentionParticuliere.contactUrgence.telephone2 =
                value;
              this.setState({ ...this.state });
            }}
            updateAutre={(value) => {
              this.state.passenger.attentionParticuliere.autres = value;
              this.setState({ ...this.state });
            }}
            estMobliliteReduite={
              this.state.passenger.attentionParticuliere.estMobliliteReduite
            }
            updateEstMobliliteReduite={(value) => {
              this.state.passenger.attentionParticuliere.estMobliliteReduite =
                value;
              this.setState({ ...this.state });
            }}
          />

          <Stack
            horizontal
            horizontalAlign={this.props.mobile ? "space-between" : "end"}
            style={{ width: "95%", marginTop: "10px" }}
          >
            <Link to="/mypassengers">
              <DefaultButton
                style={{
                  color: "white",
                  backgroundColor: this.props.theme.palette.blueDark,
                  borderRadius: 10,
                  height: "50px",
                  width: this.props.mobile ? "150px" : "250px",
                }}
              >
                {i18n.t("Passenger:Button:Cancel")}
              </DefaultButton>
            </Link>

            <DefaultButton
              style={{
                color: "white",
                backgroundColor: (
                  this.state.clientIsResident
                    ? this.canBeSaveClientResident()
                    : this.canBeSaveNotClientResident()
                )
                  ? this.props.theme.palette.blueDark
                  : this.props.theme.palette.neutralLight,
                borderRadius: 10,
                height: "50px",
                width: this.props.mobile ? "150px" : "250px",
                marginLeft: "15px",
              }}
              onClick={() => {
                this.savePassenger();
              }}
              disabled={
                this.state.clientIsResident
                  ? !this.canBeSaveClientResident()
                  : !this.canBeSaveNotClientResident()
              }
            >
              {i18n.t("Passenger:Button:Save")}
            </DefaultButton>
          </Stack>
        </Stack>
      );
    }
  }

  async GetCategorieAge(value: Date) {
    let resultDate = await PassengerServices.GetCategorieAge(value);
    return resultDate;
  }

  async setAdressClient(value: boolean) {
    let adress: IAdresse;
    if (value) {
      let user = await UserAccountServices.GetAccount();
      adress = {
        villeResidentCode: user.compteAdresse.villeResidentCode,
        memeAdresseQueClient: true,
        numeroCivique: user.compteAdresse.numeroCivique,
        rue: user.compteAdresse.rue,
        appartement: user.compteAdresse.appartement,
        casierPostal: user.compteAdresse.casierPostal,
        ville: user.compteAdresse.ville,
        provinceId: user.compteAdresse.provinceId,
        codePostal: user.compteAdresse.codePostal,
      };
    } else {
      adress = {
        villeResidentCode: "",
        memeAdresseQueClient: false,
        numeroCivique: "",
        rue: "",
        appartement: "",
        casierPostal: "",
        ville: "",
        provinceId: 0,
        codePostal: "",
      };
    }
    this.state.passenger.adresse = adress;
    this.setState({ ...this.state });
  }

  canBeSaveNotClientResident(): boolean {
    return (
      this.state.passenger.informationPassager.titre !== "" &&
      this.state.passenger.informationPassager.prenom !== "" &&
      this.state.passenger.informationPassager.nom !== "" &&
      this.state.passenger.adresse.numeroCivique !== "" &&
      this.state.passenger.adresse.rue !== "" &&
      this.state.passenger.adresse.ville !== "" &&
      this.state.passenger.adresse.codePostal !== "" &&
      this.state.passenger.adresse.codePostal.replaceAll("_", "").length === 6 &&
      this.state.passenger.adresse.provinceId !== 0 &&
      this.state.passenger.communication.courriel !== "" &&
      this.state.passenger.communication.telephone !== "" &&
      !this.state.passenger.communication.telephone.includes("_") &&
      !this.state.showMsgEmailError
    );
  }

  canBeSaveClientResident(): boolean {
    return (
      this.state.passenger.informationPassager.titre !== "" &&
      this.state.passenger.informationPassager.prenom !== "" &&
      this.state.passenger.informationPassager.nom !== "" &&
      this.state.passenger.adresse.ville !== "" &&
      this.state.passenger.adresse.codePostal !== "" &&
      this.state.passenger.adresse.codePostal.replaceAll("_", "").length ===
        6 &&
      this.state.passenger.adresse.provinceId !== null &&
      this.state.passenger.communication.courriel !== "" &&
      this.state.passenger.communication.telephone !== "" &&
      !this.state.passenger.communication.telephone.includes("_") &&
      !this.state.showMsgEmailError
    );
  }

  async savePassenger() {
    LoadingServices.setLoading("savePassenger", true);
    let categorieAge = await this.GetCategorieAge(
      this.state.passenger.informationPassager.dateDeNaissance
    );

    let passenger = this.state.passenger;
    passenger.informationPassager.categorieAge = categorieAge;

    if (!this.state.isEdit) {
      let result = await PassengerServices.AddPassenger(passenger);
      if (result < 299) {
        this.setState({ redirect: true });
      }
    } else {
      let result = await PassengerServices.EditPassenger(passenger);
      if (result < 299) {
        this.setState({ redirect: true });
      }
    }
    LoadingServices.setLoading("savePassenger", false);
  }
}
