import { styled } from "@fluentui/react/lib/Utilities";
import { ILayoutBaseProps } from "./LayoutBase.types";
import {
  getStyles,
  ILayoutBaseStyleProps,
  ILayoutBaseStyles,
} from "./LayoutBase.styles";
import { LayoutBaseComponent } from "./LayoutBase.base";

/**
 * LayoutBase
 */
export const LayoutBase = styled<
  ILayoutBaseProps,
  ILayoutBaseStyleProps,
  ILayoutBaseStyles
>(LayoutBaseComponent, getStyles);
