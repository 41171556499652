import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IConfirmationAccountSubComponentStyles {}

export interface IConfirmationAccountStyles
  extends IAppBaseStyles<IConfirmationAccountSubComponentStyles> {
  logo: IStyle;
  modalLine: IStyle;
}

export interface IConfirmationAccountStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getConfirmationAccountClassNames = (
  styles?: IStyleFunctionOrObject<
    IConfirmationAccountStyleProps,
    IConfirmationAccountStyles
  >,
  props?: IConfirmationAccountStyleProps
): IComponentBaseClassNames<
  IConfirmationAccountStyleProps,
  IConfirmationAccountStyles,
  IConfirmationAccountSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IConfirmationAccountStyleProps,
    IConfirmationAccountStyles,
    IConfirmationAccountSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IConfirmationAccountStyleProps,
  IConfirmationAccountStyles
> = (props: IConfirmationAccountStyleProps): IConfirmationAccountStyles => {
  return {
    logo: {
      maxHeight: 50,
    },

    modalLine: {
      "border-bottom-style": "solid",
      "border-bottom-width": "medium",
      "border-bottom-color": "darkblue",
    },
  };
};
