import {
  Checkbox,
  ComboBox,
  IComboBoxOption,
  MaskedTextField,
  Label,
  Stack,
  TextField,
  Dropdown,
} from "@fluentui/react";
import * as React from "react";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ProvinceServices } from "../../Services/ProvinceServices";
import { VilleServices } from "../../Services/VilleServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IAdressInformationProps,
  IAdressInformationState,
} from "./AdressInformation.types";

export class AdressInformationComponent extends React.Component<
  IAdressInformationProps,
  IAdressInformationState
> {
  constructor(props: IAdressInformationProps) {
    super(props);
    this.state = {
      villes: [],
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("GetAdressInfo", true);

    let v = await VilleServices.GetVille();
    let optionVilles: IComboBoxOption[] = [];
    v.forEach((x) => {
      optionVilles.push({ key: x.code, text: x.description });
    });

    this.setState({ villes: optionVilles });
    LoadingServices.setLoading("GetAdressInfo", false);
  }

  renderMemeAdresseClient() {
    if (this.props.isPassenger) {
      return (
        <Stack
          style={{
            marginLeft: this.props.mobile ? "" : "20px",
            marginTop: "20px",
          }}
        >
          <Checkbox
            inputProps={{ tabIndex: 9 }}
            onChange={(e, v) => {
              this.props.updateMemeAdresseClient(v);
            }}
            label={i18n.t("AdressInformation:TextFieldLabel:SameAdresse")}
          />
        </Stack>
      );
    }
  }

  renderNumeroCivique() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginTop: this.props.mobile ? "" : "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:NumeroCivique") + " "
            : ""}{" "}
          {this.props.numeroCivique}{" "}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <TextField
            tabIndex={20}
            autoComplete="nope"
            required={this.props.mobile && !this.props.isResident}
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:NumeroCivique")
                : ""
            }
            value={
              this.props.numeroCivique === "\xa0"
                ? ""
                : this.props.numeroCivique
            }
            onChange={(e, v) => {
              if (v !== undefined && v.length < 10) {
                this.props.updateNumeroCivique(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderCodePostal() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:CodePostal") + " "
            : ""}
          {this.props.codePostal}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <TextField
            tabIndex={26}
            type="text"
            name="postal-code"
            autoComplete="postal-code"
            required={this.props.mobile}
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:CodePostal")
                : ""
            }
            value={this.props.codePostal}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateCodePostal(
                  v.replaceAll(" ", "").toUpperCase()
                );
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderRue() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:Rue") + " "
            : ""}
          {this.props.rue}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <TextField
            tabIndex={21}
            type="text"
            name="none"
            autoComplete="none"
            required={this.props.mobile && !this.props.isResident}
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:Rue")
                : ""
            }
            value={this.props.rue === "\xa0" ? "" : this.props.rue}
            onChange={(e, v) => {
              if (v !== undefined && v.length < 50) {
                this.props.updateRue(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderCasierPostal() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:CassierPostal") + " "
            : ""}
          {this.props.casierPostal}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <TextField
            tabIndex={23}
            autoComplete="nope"
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:CassierPostal")
                : ""
            }
            value={
              this.props.casierPostal === "\xa0" ? "" : this.props.casierPostal
            }
            onChange={(e, v) => {
              if (v !== undefined && v.length < 15) {
                this.props.updateCasierPostal(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderProvinceDesktop() {
    if (this.props.isReadOnly || this.props.isResident) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:Province") + " "
            : ""}
          {this.props.isResident
            ? "Québec"
            : this.props.provinces.filter(
                (x) => x.key === this.props.provinceId
              )[0]?.text}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <ComboBox
            tabIndex={25}
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:Province")
                : ""
            }
            required={this.props.mobile}
            selectedKey={this.props.provinceId}
            options={this.props.provinces}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateProvince(v.key.toString());
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderProvinceMobile() {
    if (this.props.isReadOnly || this.props.isResident) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:Province") + " "
            : ""}
          {this.props.isResident
            ? "Québec"
            : this.props.provinces.filter(
                (x) => x.key === this.props.provinceId
              )[0]?.text}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <Dropdown
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:Province")
                : ""
            }
            required={this.props.mobile}
            selectedKey={this.props.provinceId}
            options={this.props.provinces}
            onChange={(e, v) => {
              if (v !== undefined) {
                this.props.updateProvince(v.key.toString());
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderAppartement() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:Appartement") + " "
            : ""}
          {this.props.appartement}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          <TextField
            tabIndex={22}
            autoComplete="nope"
            placeholder={
              this.props.mobile
                ? i18n.t("AdressInformation:TextFieldLabel:Appartement")
                : ""
            }
            value={
              this.props.appartement === "\xa0" ? "" : this.props.appartement
            }
            onChange={(e, v) => {
              if (v !== undefined && v.length < 15) {
                this.props.updateAppartement(v);
              }
            }}
          />
        </Stack>
      );
    }
  }

  renderVille() {
    if (this.props.isReadOnly) {
      return (
        <Label style={{ marginTop: "10px" }}>
          {this.props.mobile
            ? i18n.t("AdressInformation:TextFieldLabel:Ville") + " "
            : ""}
          {this.props.ville}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginTop: "10px",
            width: this.props.mobile ? "100%" : "350px",
          }}
        >
          {this.props.isResident ? (
            <Dropdown
              tabIndex={24}
              placeholder={
                this.props.mobile
                  ? i18n.t("AdressInformation:TextFieldLabel:Ville")
                  : ""
              }
              selectedKey={
                this.props.villeResidentCode !== ""
                  ? this.props.villeResidentCode
                  : ""
              }
              options={this.state.villes}
              required={this.props.mobile}
              onChange={(e, v) => {
                if (v !== undefined) {
                  this.props.updateVille(v.key.toString());
                }
              }}
            />
          ) : (
            <TextField
              tabIndex={24}
              type="text"
              name="home city"
              autoComplete="home city"
              required={this.props.mobile}
              placeholder={
                this.props.mobile
                  ? i18n.t("AdressInformation:TextFieldLabel:Ville")
                  : ""
              }
              value={this.props.ville}
              onChange={(e, v) => {
                if (v !== undefined && v.length < 50) {
                  this.props.updateVille(v);
                }
              }}
            />
          )}
        </Stack>
      );
    }
  }

  render(): JSX.Element {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("AdressInformation:H_Title:Adress")}
        isMobile={true}
        render={
          <Stack style={{ width: "90%", margin: "15px" }}>
            {this.renderMemeAdresseClient()}
            {this.renderNumeroCivique()}
            {this.renderRue()}
            {this.renderAppartement()}
            {this.renderCasierPostal()}
            {this.renderVille()}
            {this.renderProvinceMobile()}
            {this.renderCodePostal()}
          </Stack>
        }
      />
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("AdressInformation:H_Title:Adress")}
        render={
          <Stack>
            {this.renderMemeAdresseClient()}

            <Stack
              horizontal
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                marginBottom: "25px",
              }}
            >
              <Stack verticalAlign="center" style={{ minWidth: "135px" }}>
                <Label
                  style={{ marginTop: "10px" }}
                  required={!this.props.isReadOnly && !this.props.isResident}
                >
                  {i18n.t("AdressInformation:TextFieldLabel:NumeroCivique")}
                </Label>
                <Label style={{ marginTop: "10px" }}>
                  {i18n.t("AdressInformation:TextFieldLabel:Appartement")}
                </Label>
                <Label
                  style={{ marginTop: "10px" }}
                  required={!this.props.isReadOnly}
                >
                  {i18n.t("AdressInformation:TextFieldLabel:Ville")}
                </Label>
                <Label
                  style={{ marginTop: "10px" }}
                  required={!this.props.isReadOnly}
                >
                  {i18n.t("AdressInformation:TextFieldLabel:CodePostal")}
                </Label>
              </Stack>

              <Stack style={{ marginLeft: "45px" }}>
                {this.renderNumeroCivique()}
                {this.renderAppartement()}
                {this.renderVille()}
                {this.renderCodePostal()}
              </Stack>

              <Stack style={{ marginLeft: "45px" }}>
                <Label
                  style={{ marginTop: "10px" }}
                  required={!this.props.isReadOnly && !this.props.isResident}
                >
                  {i18n.t("AdressInformation:TextFieldLabel:Rue")}
                </Label>
                <Label style={{ marginTop: "10px" }}>
                  {i18n.t("AdressInformation:TextFieldLabel:CassierPostal")}
                </Label>
                <Label
                  style={{ marginTop: "10px" }}
                  required={
                    (!this.props.isReadOnly && !this.props.isPassenger) ||
                    (!this.props.isResident && !this.props.isReadOnly)
                  }
                >
                  {i18n.t("AdressInformation:TextFieldLabel:Province")}
                </Label>
              </Stack>

              <Stack style={{ marginLeft: "45px" }}>
                {this.renderRue()}
                {this.renderCasierPostal()}
                {this.renderProvinceDesktop()}
              </Stack>
            </Stack>
          </Stack>
        }
      />
    );
  }
}
