import { styled } from "@fluentui/react/lib/Utilities";
import { IInformationItineraryProps } from "./InformationItinerary.types";
import {
  getStyles,
  IInformationItineraryStyleProps,
  IInformationItineraryStyles,
} from "./InformationItinerary.styles";
import { InformationItineraryComponent } from "./InformationItinerary.base";

/**
 * InformationItinerary
 */
export const InformationItinerary = styled<
  IInformationItineraryProps,
  IInformationItineraryStyleProps,
  IInformationItineraryStyles
>(InformationItineraryComponent, getStyles);
