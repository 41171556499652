import { styled } from "@fluentui/react/lib/Utilities";
import { ICurrentBookingProps } from "./CurrentBooking.types";
import {
  getStyles,
  ICurrentBookingStyleProps,
  ICurrentBookingStyles,
} from "./CurrentBooking.styles";
import { CurrentBookingComponent } from "./CurrentBooking.base";

/**
 * CurrentBooking
 */
export const CurrentBooking = styled<
  ICurrentBookingProps,
  ICurrentBookingStyleProps,
  ICurrentBookingStyles
>(CurrentBookingComponent, getStyles);
