import { Label, Stack, TextField } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import i18n from "../../Services/i18n";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  ILoginInformationProps,
  ILoginInformationState,
} from "./LoginInformation.types";
export class LoginInformationComponent extends React.Component<
  ILoginInformationProps,
  ILoginInformationState
> {
  constructor(props: ILoginInformationProps) {
    super(props);
  }

  render(): JSX.Element {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderRegexMotDePasseError() {
    if (this.props.showRegexPW) {
      return (
        <Stack style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
          <Label style={{ color: "red", marginBottom: "10px" }}>
            {i18n.t("LoginInformation:MsgError:RegexEmail")}
          </Label>
        </Stack>
      );
    } else if (!this.props.mobile) {
      return <Stack style={{ marginBottom: "30px" }}></Stack>;
    }
  }

  renderCourriel() {
    if (this.props.isReadOnly || this.props.emailReadOnly) {
      return (
        <Label style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
          {this.props.mobile
            ? i18n.t("LoginInformation:TextFieldLabel:Email") + " "
            : ""}
          {this.props.courriel}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            width: this.props.mobile ? "100%" : "80%",
            minHeight: this.props.mobile ? "65px" : "",
          }}
          horizontal={!this.props.mobile}
        >
          <Stack style={{ width: this.props.mobile ? "100%" : "60%" }}>
            <TextField
              tabIndex={10}
              type="text"
              name="email"
              autoComplete="email"
              validateOnFocusOut
              onGetErrorMessage={(v) => {
                if (v !== undefined && v.length > 0) {
                  this.props.updateCourriel(v);
                } else if (this.props.emailWasChange) {
                  this.props.updateCourriel(v);
                }
                return "";
              }}
              required={this.props.mobile}
              placeholder={
                this.props.mobile
                  ? i18n.t("LoginInformation:TextFieldLabel:Email")
                  : ""
              }
            />
          </Stack>
          {this.props.showEmailError ? (
            <Stack style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
              <Label style={{ color: "red" }}>
                {i18n.t("LoginInformation:MsgError:EmailInvalid")}
              </Label>
            </Stack>
          ) : (
            ""
          )}
          {this.props.showEmailExistError ? (
            <Stack style={{ marginLeft: this.props.mobile ? "" : "20px" }}>
              <Label style={{ color: "red" }}>
                {i18n.t("LoginInformation:MsgError:EmailAlreadyUse")}{" "}
                <Link to="/signin">
                  {i18n.t("LoginInformation:MsgError:Connect")}
                </Link>
              </Label>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      );
    }
  }

  renderMotDePasse() {
    if (!this.props.isReadOnly) {
      return (
        <Stack horizontal>
          {!this.props.mobile ? (
            <Stack verticalAlign="center" style={{ marginRight: "15px" }}>
              <Label
                required={!this.props.isReadOnly}
                style={{ textAlign: "center" }}
              >
                {i18n.t("LoginInformation:TextFieldLabel:Password")}
              </Label>
            </Stack>
          ) : (
            ""
          )}
          <Stack
            style={{
              width: this.props.mobile ? "100%" : "",
            }}
          >
            <TextField
              tabIndex={11}
              validateOnFocusOut
              onGetErrorMessage={(v) => {
                if (v !== undefined && v.length > 0) {
                  this.props.updateMotDePasse(v);
                } else if (this.props.pwWasChange) {
                  this.props.updateMotDePasse(v);
                }
                return "";
              }}
              required={this.props.mobile}
              placeholder={
                this.props.mobile
                  ? i18n.t("LoginInformation:TextFieldLabel:Password")
                  : ""
              }
              type="password"
              canRevealPassword
              revealPasswordAriaLabel="Show password"
            />
          </Stack>
        </Stack>
      );
    }
  }

  renderMobile(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("LoginInformation:H_Title:InformationConex")}
        isMobile={true}
        render={
          <Stack style={{ width: "90%", margin: "15px" }}>
            {this.renderCourriel()}
            {this.renderMotDePasse()}
            {this.renderConfirmMotDePasse()}
            {this.renderRegexMotDePasseError()}
          </Stack>
        }
      />
    );
  }

  renderConfirmMotDePasse() {
    if (!this.props.isReadOnly) {
      return (
        <Stack
          horizontal={!this.props.mobile}
          style={{ marginTop: this.props.mobile ? "10px" : "" }}
        >
          <Stack horizontal={!this.props.mobile}>
            {!this.props.mobile ? (
              <Label
                required={!this.props.isReadOnly}
                style={{
                  textAlign: "center",
                  marginRight: "40px",
                  marginLeft: "40px",
                }}
              >
                {i18n.t("LoginInformation:TextFieldLabel:PasswordConfirm")}
              </Label>
            ) : (
              ""
            )}

            <TextField
              tabIndex={12}
              validateOnFocusOut
              onGetErrorMessage={(v) => {
                if (v !== undefined && v.length > 0) {
                  this.props.updateConfrimMotDePasse(v);
                } else if (this.props.confirmPwWasChange) {
                  this.props.updateConfrimMotDePasse(v);
                }
                return "";
              }}
              type="password"
              required={this.props.mobile}
              placeholder={
                this.props.mobile
                  ? i18n.t("LoginInformation:TextFieldLabel:PasswordConfirm")
                  : ""
              }
              canRevealPassword
              revealPasswordAriaLabel="Show password"
            />
          </Stack>

          {this.props.showConfirmPWError ? (
            <Label
              style={{
                textAlign: this.props.mobile ? "start" : "center",
                marginRight: this.props.mobile ? "" : "40px",
                marginLeft: this.props.mobile ? "" : "40px",
                color: "red",
              }}
            >
              {i18n.t("LoginInformation:MsgError:ConfirmPW")}
            </Label>
          ) : (
            ""
          )}
        </Stack>
      );
    }
  }

  renderDesktop(): JSX.Element {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("LoginInformation:H_Title:InformationConex")}
        render={
          <Stack>
            <Stack
              horizontalAlign="start"
              horizontal
              style={{ marginLeft: "20px", marginTop: "40px" }}
            >
              <Stack style={{ marginRight: "15px" }}>
                <Label required={!this.props.isReadOnly}>
                  {i18n.t("LoginInformation:TextFieldLabel:Email")}
                </Label>
              </Stack>

              {this.renderCourriel()}
            </Stack>

            <Stack
              horizontal
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                marginBottom: "25px",
              }}
            >
              {this.renderMotDePasse()}
              {this.renderConfirmMotDePasse()}
            </Stack>
            {this.renderRegexMotDePasseError()}
          </Stack>
        }
      />
    );
  }
}
