import * as React from "react";
import { getLegalConditionsClassNames } from "./LegalConditions.styles";
import {
  ILegalConditionsState,
  ILegalConditionsProps,
} from "./LegalConditions.types";

export class LegalConditionsComponent extends React.Component<
  ILegalConditionsProps,
  ILegalConditionsState
> {
  constructor(props: ILegalConditionsProps) {
    super(props);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getLegalConditionsClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return <div className={classNames.root}>Conditions légal</div>;
  }
}
