import { styled } from "@fluentui/react/lib/Utilities";
import { IReservationVehicleProps } from "./ReservationVehicle.types";
import {
  getStyles,
  IReservationVehicleStyleProps,
  IReservationVehicleStyles,
} from "./ReservationVehicle.styles";
import { ReservationVehicleComponent } from "./ReservationVehicle.base";

/**
 * ReservationVehicle
 */
export const ReservationVehicle = styled<
  IReservationVehicleProps,
  IReservationVehicleStyleProps,
  IReservationVehicleStyles
>(ReservationVehicleComponent, getStyles);
