import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ILoginInformationSubComponentStyles {}

export interface ILoginInformationStyles
  extends IAppBaseStyles<ILoginInformationSubComponentStyles> {}

export interface ILoginInformationStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getLoginInformationClassNames = (
  styles?: IStyleFunctionOrObject<
    ILoginInformationStyleProps,
    ILoginInformationStyles
  >,
  props?: ILoginInformationStyleProps
): IComponentBaseClassNames<
  ILoginInformationStyleProps,
  ILoginInformationStyles,
  ILoginInformationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ILoginInformationStyleProps,
    ILoginInformationStyles,
    ILoginInformationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  ILoginInformationStyleProps,
  ILoginInformationStyles
> = (props: ILoginInformationStyleProps): ILoginInformationStyles => {
  return {
    root: ["app-LoginInformation", {}],
  };
};
