import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../Base";

export interface IAppRouterSubComponentStyles {}

export interface IAppRouterStyles
  extends IAppBaseStyles<IAppRouterSubComponentStyles> {}

export interface IAppRouterStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getAppRouterClassNames = (
  styles?: IStyleFunctionOrObject<IAppRouterStyleProps, IAppRouterStyles>,
  props?: IAppRouterStyleProps
): IComponentBaseClassNames<
  IAppRouterStyleProps,
  IAppRouterStyles,
  IAppRouterSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IAppRouterStyleProps,
    IAppRouterStyles,
    IAppRouterSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IAppRouterStyleProps,
  IAppRouterStyles
> = (props: IAppRouterStyleProps): IAppRouterStyles => {
  return {
    root: ["app-AppRouter", {}],
  };
};
