import { styled } from "@fluentui/react/lib/Utilities";
import { IBasicInformationProps } from "./BasicInformation.types";
import {
  getStyles,
  IBasicInformationStyleProps,
  IBasicInformationStyles,
} from "./BasicInformation.styles";
import { BasicInformationComponent } from "./BasicInformation.base";

/**
 * BasicInformation
 */
export const BasicInformation = styled<
  IBasicInformationProps,
  IBasicInformationStyleProps,
  IBasicInformationStyles
>(BasicInformationComponent, getStyles);
