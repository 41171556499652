import { DefaultButton, IconButton, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { IMiniReservation } from "../../Models/IMiniReservation";
import { IReservationTransferEnCours } from "../../Models/IReservationTransferEnCours";
import CurrencyService from "../../Services/CurrencyService/CurrencyService";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { ModalWithSimpleBanner } from "../ModalWithSimpleBanner";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { IReservationsProps, IReservationsState } from "./Reservations.types";

export class ReservationsComponent extends React.Component<
  IReservationsProps,
  IReservationsState
> {
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
      marginLeft: "5px",
      width: "10px",
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };

  constructor(props: IReservationsProps) {
    super(props);
    this.state = {
      showModal: false,
      itemId: "0",
      showModalSucces: false,
      showModalError: false,
      reservationTransferEnCours: [],
    };
  }
  async componentDidMount() {
    if (this.props.isCurrent) {
      let rctc = await ReservationServices.GetReservationTransfertEnCours();
      this.setState({ reservationTransferEnCours: rctc });
    }
  }

  render(): JSX.Element {
    return this.props.mobile ? this.renderMobile() : this.renderDesktop();
  }

  renderMobile(): JSX.Element {
    return (
      <Stack>
        {this.renderModal()}
        {this.renderModalSuccess()}
        {this.renderModalError()}

        {this.props.isCurrent &&
        this.state.reservationTransferEnCours.length > 0 ? (
          <Stack style={{ marginBottom: "15px" }}>
            <h3 style={{ margin: "10px" }}>
              {i18n.t("CB:Label:ResTraitement")}
            </h3>
            {this.state.reservationTransferEnCours.map((x, index) => {
              return this.renderItemMobileTransfereWebCargo(x, index);
            })}
          </Stack>
        ) : (
          ""
        )}
        {this.props.isCurrent ? (
          <Stack>
            <h3 style={{ margin: "10px" }}>{i18n.t("CB:Label:MRC")}</h3>
            {this.props.miniReservations.map((x, index) => {
              return this.renderItemMobile(x, index);
            })}
          </Stack>
        ) : (
          <Stack>
            <h3 style={{ margin: "10px" }}>{i18n.t("CB:Label:History")}</h3>
            {this.props.miniReservations.map((x, index) => {
              return this.renderItemMobile(x, index);
            })}
          </Stack>
        )}
      </Stack>
    );
  }

  renderItemMobile(item: IMiniReservation, index: number) {
    let link = "/documentreservation/id=" + item.reservationId;
    return (
      <Stack
        key={index}
        style={{
          border: "1px solid black",
          borderRadius: 15,
          width: "95%",
          marginTop: "15px",
          marginLeft: "10px",
        }}
      >
        <Stack horizontal style={{ marginTop: "10px" }}>
          <Label style={{ marginLeft: "10px" }}>
            {i18n.t("IF:Label:Voyage")}
          </Label>
          <Label
            style={{
              marginLeft: "10px",
              color: this.props.theme.palette.blueLight,
            }}
          >
            0{item.numeroVoyage}
          </Label>
          <Label style={{ marginLeft: "40px" }}>
            {i18n.t("CB:Label:Reservation")}
          </Label>
          <Link style={{ marginLeft: "10px", marginTop: "5px" }} to={link}>
            {item.numeroReservation}
          </Link>          
        </Stack>

        <Stack horizontal>
          <Stack style={{ marginTop: "10px", width: "70%" }}>
            <Label style={{ marginLeft: "10px" }}>{i18n.t("CB:Label:OD")} </Label>
            <Label style={{ marginLeft: "20px", fontWeight:"normal" }}>
              {item.origineVoyage}-{item.destinationVoyage}
            </Label>
          </Stack>

          {this.props.isCurrent && item.estAnnulable ? (
            <Stack>
              <IconButton
                style={{ marginLeft: "25px", marginTop: "20px", width: "20%"  }}
                iconProps={{ iconName: "trash" }}
                styles={this._iconButtonStyles}
                onClick={() => {
                  this.setState({ showModal: true,
                    itemId: item.numeroReservation });
                }}
              />
            </Stack>
          ) : (
            ""
          )}

          </Stack>
        </Stack>
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <Stack
        style={{
          marginLeft: "20px",
          marginBottom: !this.props.isCurrent ? "100px" : "",
        }}
      >
        {this.renderModal()}
        {this.renderModalSuccess()}
        {this.renderModalError()}

        {this.props.isCurrent &&
        this.state.reservationTransferEnCours.length > 0 ? (
          <NvxCustomBorderRadiusStack
            {...this.props}
            title={i18n.t("CB:Label:ResTraitement")}
            render={
              <Stack
                style={{
                  marginLeft: "20px",
                  marginTop: "20px",
                  width: "95%",
                  marginBottom: "20px",
                }}
                verticalAlign="center"
                horizontalAlign="space-between"
              >
                <Stack horizontal>
                  <Label
                    style={{
                      minWidth: "25%",
                      color: this.props.theme.palette.blueLight,
                    }}
                  >
                    {i18n.t("CB:Label:Voyager")}
                  </Label>
                  <Label
                    style={{
                      minWidth: "25%",
                      color: this.props.theme.palette.blueLight,
                    }}
                  >
                    {i18n.t("CB:Label:DateDeDepart")}
                  </Label>
                  <Label
                    style={{
                      minWidth: "25%",
                      color: this.props.theme.palette.blueLight,
                    }}
                  >
                    {i18n.t("CB:Label:MontantPayer")}
                  </Label>
                  {this.props.isCurrent ? (
                    <Label
                      style={{
                        minWidth: "25%",
                        color: this.props.theme.palette.blueLight,
                      }}
                    >
                    {i18n.t("CB:Label:NoConfirmation")}                     

                    </Label>
                  ) : (
                    ""
                  )}
                </Stack>
                <Stack>
                  {this.renderItemTransfert(
                    this.state.reservationTransferEnCours
                  )}
                </Stack>
              </Stack>
            }
          />
        ) : (
          ""
        )}

        <NvxCustomBorderRadiusStack
          {...this.props}
          title={
            this.props.isCurrent
              ? i18n.t("CB:Label:MRC")
              : i18n.t("CB:Label:History")
          }
          render={
            <Stack
              style={{
                marginLeft: "20px",
                marginTop: "20px",
                width: "95%",
                marginBottom: "20px",
              }}
              verticalAlign="center"
              horizontalAlign="space-between"
            >
              <Stack horizontal>
                <Label
                  style={{
                    minWidth: "25%",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("CB:Label:Voyager")}
                </Label>
                <Label
                  style={{
                    minWidth: "25%",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("CB:Label:Reservation")}
                </Label>
                <Label
                  style={{
                    minWidth: "25%",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("CB:Label:OD")}
                </Label>
                {this.props.isCurrent ? (
                  <Label
                    style={{
                      minWidth: "25%",
                      color: this.props.theme.palette.blueLight,
                    }}
                  >
                    {i18n.t("CB:Label:Action")}
                  </Label>
                ) : (
                  ""
                )}
              </Stack>
              <Stack>
                {this.props.isCurrent
                  ? this.renderItem(this.props.miniReservations)
                  : this.renderItem(this.props.miniReservations)}
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderModal() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModal: false });
          }}
          redirectUrlToClose={"/currentbooking"}
          {...this.props}
          isOpen={this.state.showModal}
          render={
            <Stack style={{ marginLeft: "25px" }}>
              <h3 style={{ borderBottom: "solid", marginRight: "25px" }}>
                {i18n.t("CB:H3:ModalDelete")}
              </h3>
              <Stack
                style={{
                  marginRight: "50px",
                  marginLeft: "25px",
                }}
                horizontalAlign="center"
              >
                <Label style={{ color: this.props.theme.palette.blueLight, textAlign:"justify", textJustify:"inter-word" }}>
                  {i18n.t("CB:label:MoreInformationLigne1")}
                </Label>
                <Label style={{ color: this.props.theme.palette.blueLight, textAlign:"justify", textJustify:"inter-word" }}>
                  {i18n.t("CB:label:MoreInformationLigne2")}
                </Label>
              </Stack>

              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{
                  marginRight: "75px",
                  marginLeft: "50px",
                  marginBottom: "10px",
                  marginTop: "20px",
                }}
              >
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={() => {
                    this.setState({ showModal: false, itemId: "" });
                  }}
                >
                  {i18n.t("CB:Label:No")}
                </DefaultButton>
                <DefaultButton
                  style={{
                    color: "white",
                    backgroundColor: this.props.theme.palette.blueDark,
                    borderRadius: 10,
                    height: "40px",
                    width: "150px",
                  }}
                  onClick={() => {
                    this.cancelReservation();
                  }}
                >
                  {i18n.t("CB:Label:Yes")}
                </DefaultButton>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }

  async cancelReservation() {
    LoadingServices.setLoading("cancelReservation", true);
    let sucess = await ReservationServices.AnnulerReservation(
      this.state.itemId
    );
    if (sucess) {
      this.setState({ showModalSucces: true, showModal: false });
    } else {
      this.setState({ showModalError: true, showModal: false });
    }
    LoadingServices.setLoading("cancelReservation", false);
  }

  renderItemMobileTransfereWebCargo(
    x: IReservationTransferEnCours,
    index: number
  ) {
    return (
      <Stack
        key={index}
        style={{
          border: "1px solid black",
          borderRadius: 15,
          width: "95%",
          marginTop: "15px",
          marginLeft: "10px",
        }}
      >
        <Stack style={{ marginLeft: "10px" }}>
          <Stack style={{ minWidth: "25%" }}>
            <Label>Trip : {x.noVoyage}</Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {i18n.t("IF:Label:DateDeDepart")}{" "}
              {CustomStringServices.FormatDateAvecHeure(new Date(x.dateDepart), true)}
            </Label>
          </Stack>

          <Label style={{ minWidth: "25%" }}>
            Montant à payer : {x.montantPayer}
          </Label>
          <Label style={{ minWidth: "25%" }}>
            Numéro de confirmation :{x.noConfirmation}
          </Label>
        </Stack>
      </Stack>
    );
  }

  renderItemTransfert(items: IReservationTransferEnCours[]): JSX.Element[] {
    let element = items.map((x, index) => {
      return (
        <Stack
          key={index}
          horizontal
          style={{
            marginTop: "10px",
            borderTopWidth: "thin",
            borderTopStyle: "solid",
          }}
        >
          <Stack style={{ minWidth: "25%" }}>
            <Label>{x.noVoyage}</Label>
          </Stack>

          <Label style={{ minWidth: "25%" }}>
            {CustomStringServices.FormatDateAvecHeure(
              new Date(x.dateDepart),
              true
            )}
          </Label>

          <Label style={{ minWidth: "25%" }}>
            {CurrencyService.ToDollars(x.montantPayer)}
          </Label>
          <Label style={{ minWidth: "25%" }}>{x.noConfirmation}</Label>
        </Stack>
      );
    });
    return element;
  }

  renderItem(items: IMiniReservation[]): JSX.Element[] {
    let element = items.map((x, index) => {
      let link = "/documentreservation/id=" + x.reservationId;
      return (
        <Stack
          key={index}
          horizontal
          style={{
            marginTop: "10px",
            borderTopWidth: "thin",
            borderTopStyle: "solid",
          }}
        >
          <Stack style={{ minWidth: "25%" }}>
            <Label>0{x.numeroVoyage}</Label>
            <Label style={{ color: this.props.theme.palette.blueLight }}>
              {CustomStringServices.FormatDateAvecHeure(new Date(x.dateDepart))}
            </Label>
          </Stack>

          <Link style={{ minWidth: "25%" }} to={link}>
            {x.numeroReservation}
          </Link>

          <Label style={{ minWidth: "25%" }}>
            {x.origineVoyage}-{x.destinationVoyage}
          </Label>
          {this.props.isCurrent && x.estAnnulable ? (
            <Stack>
              <IconButton
                style={{ marginLeft: "20px" }}
                iconProps={{ iconName: "trash" }}
                styles={this._iconButtonStyles}
                onClick={() => {
                  this.setState({
                    showModal: true,
                    itemId: x.numeroReservation,
                  });
                }}
              />
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      );
    });
    return element;
  }

  renderModalError() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModalError: false });
            window.location.reload();
          }}
          redirectUrlToClose={"/currentbooking"}
          {...this.props}
          isOpen={this.state.showModalError}
          render={
            <Stack
              style={{
                marginRight: "50px",
                marginLeft: "50px",
                marginTop: "25px",
              }}
              horizontalAlign="center"
            >
              <Label
                style={{
                  color: this.props.theme.palette.blueLight,
                  fontSize: "14px",
                }}
              >
                {i18n.t("app:genericError")}
              </Label>
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderModalSuccess() {
    return (
      <Stack>
        <ModalWithSimpleBanner
          onClose={() => {
            this.setState({ showModalSucces: false });
            window.location.reload();
          }}
          redirectUrlToClose={"/currentbooking"}
          {...this.props}
          isOpen={this.state.showModalSucces}
          render={
            <Stack style={{ marginLeft: "25px" }}>
              <Stack
                style={{
                  marginRight: "50px",
                  marginLeft: "25px",
                }}
                horizontalAlign="center"
              >
                <Label style={{ color: this.props.theme.palette.blueLight }}>
                  {i18n.t("CB:label:ConfirmationAnnulationLigne1")} 
                </Label>
                <Label style={{ color: this.props.theme.palette.blueLight }}>
                  {i18n.t("CB:label:ConfirmationAnnulationLigne2")} 
                </Label>
              </Stack>
            </Stack>
          }
        />
      </Stack>
    );
  }
}
