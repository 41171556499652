import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IConfirmationPaymentSubComponentStyles {}

export interface IConfirmationPaymentStyles
  extends IAppBaseStyles<IConfirmationPaymentSubComponentStyles> {}

export interface IConfirmationPaymentStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getConfirmationPaymentClassNames = (
  styles?: IStyleFunctionOrObject<
    IConfirmationPaymentStyleProps,
    IConfirmationPaymentStyles
  >,
  props?: IConfirmationPaymentStyleProps
): IComponentBaseClassNames<
  IConfirmationPaymentStyleProps,
  IConfirmationPaymentStyles,
  IConfirmationPaymentSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IConfirmationPaymentStyleProps,
    IConfirmationPaymentStyles,
    IConfirmationPaymentSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IConfirmationPaymentStyleProps,
  IConfirmationPaymentStyles
> = (props: IConfirmationPaymentStyleProps): IConfirmationPaymentStyles => {
  return {
    root: ["app-ConfirmationPayment", {}],
  };
};
