import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";

export interface IPassengerSubComponentStyles {}

export interface IPassengerStyles
  extends IAppBaseStyles<IPassengerSubComponentStyles> {}

export interface IPassengerStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getPassengerClassNames = (
  styles?: IStyleFunctionOrObject<IPassengerStyleProps, IPassengerStyles>,
  props?: IPassengerStyleProps
): IComponentBaseClassNames<
  IPassengerStyleProps,
  IPassengerStyles,
  IPassengerSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IPassengerStyleProps,
    IPassengerStyles,
    IPassengerSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IPassengerStyleProps,
  IPassengerStyles
> = (props: IPassengerStyleProps): IPassengerStyles => {
  return {
    root: ["app-AddPassenger", {}],
  };
};
