import { styled } from "@fluentui/react/lib/Utilities";
import { INvxCustomBorderRadiusStackProps } from "./NvxCustomBorderRadiusStack.types";
import {
  getStyles,
  INvxCustomBorderRadiusStackStyleProps,
  INvxCustomBorderRadiusStackStyles,
} from "./NvxCustomBorderRadiusStack.styles";
import { NvxCustomBorderRadiusStackComponent } from "./NvxCustomBorderRadiusStack.base";

/**
 * NvxCustomBorderRadiusStack
 */
export const NvxCustomBorderRadiusStack = styled<
  INvxCustomBorderRadiusStackProps,
  INvxCustomBorderRadiusStackStyleProps,
  INvxCustomBorderRadiusStackStyles
>(NvxCustomBorderRadiusStackComponent, getStyles);
