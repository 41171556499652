import LocalizedStrings from "react-localization";
import locales from "../Locales";

class I18n {
  private strings = new LocalizedStrings(locales);

  constructor() {
    let lang = "fr";

    //  Puisque l'application est seulement en anglais
    //  la variable serait tjrs en pour le moment
    const obj = localStorage.getItem("fr");

    if (obj !== undefined && obj !== null) {
      lang = obj;
    }
    this.strings.setLanguage(lang);
  }

  public t(key: string): string {
    return this.strings.getString(key) ?? key;
  }

  public changeLanguage(lang: string): void {
    lang = lang.toLowerCase();
    if (lang === "fr") {
      lang = "an";
    } else if (lang === "an") {
      lang = "fr";
    }
    localStorage.setItem("lang", lang);
    this.strings.setLanguage(lang);
  }

  public setLanguage(lang: string): void {
    lang = lang.toLowerCase();
    localStorage.setItem("lang", lang);
    this.strings.setLanguage(lang);
  }

  public setLanguageOnLoading(lang: string): void {
    lang = lang.toLowerCase();
    if (lang === null || lang === undefined) {
      lang = "fr";
    }

    localStorage.setItem("lang", lang);
    this.strings.setLanguage(lang);
  }

  public getLanguage(): string {
    return this.strings.getLanguage();
  }
}

export default new I18n();
