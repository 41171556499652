import { IconButton, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { Navigate } from "react-router";
import CustomStringServices from "../../Services/CustomStringServices/CustomStringServices";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationImpressionServices } from "../../Services/ReservationImpressionServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  IDocumentReservationProps,
  IDocumentReservationState,
} from "./DocumentReservation.types";

export class DocumentReservationComponent extends React.Component<
  IDocumentReservationProps,
  IDocumentReservationState
> {
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
      marginTop: "4px",
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };
  constructor(props: IDocumentReservationProps) {
    super(props);
    this.state = {
      redirectNoReservation: false,
      reservation: {
        troncon: "",
        dateEmbarquement: null,
        reservationId: "",
        numeroReservation: "",
        vehiculeListe: [],
        passagerListe: [],
        serviceListe: [],
      },
      isLoading: true,
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("DocumentReservation", true);

    if (window.location.href.includes("=")) {
      let urlSplit = window.location.href.split("/");
      let reservationId = urlSplit[4].split("=")[1];

      if (reservationId === "") {
        this.setState({ redirectNoReservation: true });
      } else {
        let r = await ReservationServices.GetReservation(reservationId);
        this.setState({ reservation: r, isLoading: false });
      }
    } else {
      this.setState({ redirectNoReservation: true });
    }

    LoadingServices.setLoading("DocumentReservation", false);
  }

  render(): JSX.Element {
    if (this.state.redirectNoReservation) {
      return <Navigate to="/history" />;
    } else if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return this.props.mobile ? this.renderMobile() : this.renderDesktop();
    }
  }

  renderMobile(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "20px" }}>
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={
            i18n.t("DR:Title:DC") +
            " - " +
            this.state.reservation.numeroReservation
          }
          render={
            <Stack
              style={{
                marginBottom: "20px",
                marginLeft: "20px",
              }}
            >
              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginRight: "20px" }}
              >
                <Label style={{ color: this.props.theme.palette.blueLight }}>
                  {i18n.t("DR:Label:Trocon")}
                </Label>
                <Label>{this.state.reservation.troncon}</Label>
              </Stack>

              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginRight: "20px" }}
              >
                <Label style={{ color: this.props.theme.palette.blueLight }}>
                  {i18n.t("DR:Label:DE")}
                </Label>
                <Label style={{ marginLeft: "20px" }}>
                  {CustomStringServices.FormatDateAvecHeure(
                    this.state.reservation.dateEmbarquement
                  )}
                </Label>
              </Stack>

              <Stack
                horizontal
                horizontalAlign="space-between"
                style={{ marginRight: "20px" }}
              >
                <Label
                  style={{
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("DR:Label:NR")} :
                </Label>
                <Label style={{ marginLeft: "20px" }}>
                  {this.state.reservation.numeroReservation}
                </Label>
              </Stack>

              <Stack>
                <Label
                  style={{
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("DR:Label:Services")}
                </Label>
              </Stack>

              {this.state.reservation.passagerListe.length > 0 ? (
                <Stack>
                  <Label
                    style={{
                      minWidth: "180px",
                      color: this.props.theme.palette.blueLight,
                      fontSize: "19px",
                    }}
                  >
                    {i18n.t("DR:Label:PassageMaritime")}
                  </Label>

                  <Stack>
                    {this.state.reservation.passagerListe.map((x, index) => {
                      return (
                        <Stack
                          horizontal
                          key={index}
                          style={{ marginLeft: "10px" }}
                        >
                          <Label
                            style={{
                              minWidth: "220px",
                              maxWidth: "220px",
                            }}
                          >
                            {x.libelle}
                          </Label>

                          <IconButton
                            iconProps={{ iconName: "ticket" }}
                            onClick={async () => {
                              LoadingServices.setLoading(
                                "GetCarteEmbarquement",
                                true
                              );
                              await ReservationImpressionServices.GetCarteEmbarquement(
                                x.id
                              );
                              LoadingServices.setLoading(
                                "GetCarteEmbarquement",
                                false
                              );
                            }}
                          />
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              ) : (
                ""
              )}

              {this.state.reservation.vehiculeListe.length > 0 ? (
                <Stack>
                  <Label
                    style={{
                      minWidth: "180px",
                      color: this.props.theme.palette.blueLight,
                      fontSize: "19px",
                    }}
                  >
                    {i18n.t("DR:Label:Vehicule")}
                  </Label>

                  <Stack>
                    {this.state.reservation.vehiculeListe.map((x, index) => {
                      return (
                        <Stack
                          horizontal
                          key={index}
                          style={{ marginLeft: "10px" }}
                        >
                          <Label
                            style={{
                              minWidth: "220px",
                              maxWidth: "220px",
                            }}
                          >
                            {x.libelle}
                          </Label>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              ) : (
                ""
              )}
              {this.renderInfo()}
            </Stack>
          }
        />
      </Stack>
    );
  }

  renderInfo() {
    return (
      <Stack>
        <Label style={{ color: this.props.theme.palette.blueLight }}>
          {i18n.t("DR:Label:Impression")}
        </Label>
        <Stack style={{ marginLeft: "10px" }}>
          <Label
            style={{
              cursor: "pointer",
              maxWidth: "130px",
              textDecoration: "underline",
            }}
            onClick={async () => {
              LoadingServices.setLoading("GetFicheReservation", true);
              await ReservationImpressionServices.GetFicheReservation(
                this.state.reservation.reservationId
              );
              LoadingServices.setLoading("GetFicheReservation", false);
            }}
            onMouseOver={(e) => this.MouseOver(e)}
            onMouseOut={(e) => this.MouseOut(e)}
          >
            {i18n.t("DR:Label:FicheReservation")}
          </Label>{" "}
          {this.state.reservation.vehiculeListe.length > 0 ? (
            <Label
              style={{
                cursor: "pointer",
                maxWidth: "130px",
                textDecoration: "underline",
              }}
              onMouseOver={(e) => this.MouseOver(e)}
              onMouseOut={(e) => this.MouseOut(e)}
              onClick={async () => {
                LoadingServices.setLoading("GetFicheReservation", true);
                await ReservationImpressionServices.GetLettreTransport(
                  this.state.reservation.reservationId
                );
                LoadingServices.setLoading("GetFicheReservation", false);
              }}
            >
              {i18n.t("DR:Label:LettreTransport")}
            </Label>
          ) : (
            ""
          )}
        </Stack>
      </Stack>
    );
  }

  renderDesktop(): JSX.Element {
    return (
      <Stack style={{ marginLeft: "20px" }}>
        <NvxCustomBorderRadiusStack
          {...this.props}
          title={
            i18n.t("DR:Title:DC") +
            " - " +
            this.state.reservation.numeroReservation
          }
          render={
            <Stack
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                marginLeft: "30px",
              }}
            >
              <Stack horizontal>
                <Label style={{ color: this.props.theme.palette.blueLight }}>
                  {i18n.t("DR:Label:Trocon")}
                </Label>
                <Label>{this.state.reservation.troncon}</Label>
              </Stack>

              <Stack horizontal>
                <Label style={{ color: this.props.theme.palette.blueLight }}>
                  {i18n.t("DR:Label:DE")}
                </Label>
                <Label>
                  {CustomStringServices.FormatDateAvecHeure(
                    this.state.reservation.dateEmbarquement
                  )}
                </Label>
              </Stack>

              <Stack horizontal>
                <Label
                  style={{
                    minWidth: "180px",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("DR:Label:NR")}
                </Label>
                <Label
                  style={{
                    minWidth: "180px",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("DR:Label:Services")}
                </Label>
                <Label
                  style={{
                    minWidth: "300px",
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("DR:Label:Description")}
                </Label>

                <Label
                  style={{
                    color: this.props.theme.palette.blueLight,
                  }}
                >
                  {i18n.t("DR:Label:Billet")}
                </Label>
              </Stack>

              <Stack horizontal>
                <Label
                  style={{
                    minWidth: "180px",
                  }}
                >
                  {this.state.reservation.numeroReservation}
                </Label>
                {this.state.reservation.passagerListe.length > 0 ? (
                  <Stack horizontal>
                    <Label
                      style={{
                        minWidth: "180px",
                      }}
                    >
                      {i18n.t("DR:Label:PassageMaritime")}
                    </Label>

                    <Stack>
                      {this.state.reservation.passagerListe.map((x, index) => {
                        return (
                          <Stack horizontal key={index}>
                            <Label
                              style={{
                                minWidth: "300px",
                              }}
                            >
                              {x.libelle}
                            </Label>

                            <IconButton
                              iconProps={{ iconName: "ticket" }}
                              onClick={async () => {
                                LoadingServices.setLoading(
                                  "GetCarteEmbarquement",
                                  true
                                );
                                await ReservationImpressionServices.GetCarteEmbarquement(
                                  x.id
                                );
                                LoadingServices.setLoading(
                                  "GetCarteEmbarquement",
                                  false
                                );
                              }}
                            />
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Stack>
                ) : (
                  ""
                )}
              </Stack>
              {this.state.reservation.vehiculeListe.length > 0 ? (
                <Stack horizontal>
                  <Label
                    style={{
                      minWidth: "180px",
                      marginLeft: "180px",
                    }}
                  >
                    {i18n.t("DR:Label:Vehicule")}
                  </Label>

                  <Stack>
                    {this.state.reservation.vehiculeListe.map((x, index) => {
                      return (
                        <Stack horizontal key={index}>
                          <Label
                            style={{
                              minWidth: "300px",
                              maxWidth: "300px",
                            }}
                          >
                            {x.libelle}
                          </Label>
                        </Stack>
                      );
                    })}
                  </Stack>
                </Stack>
              ) : (
                ""
              )}
              {this.renderInfo()}
            </Stack>
          }
        />
      </Stack>
    );
  }
  MouseOver(event: any) {
    event.target.style.color = this.props.theme.palette.blue;
  }
  MouseOut(event: any) {
    event.target.style.color = "";
  }
}
