export class StylingServices {
  public static GetGradationColor(value: number, valueMax: number): string {
    const percent = value*100/valueMax
    
    if (percent > 99 || value >= valueMax)
        return "red"
    else if ( percent >= 33)
        return "orange"
    else
        return "green"
  }
}
