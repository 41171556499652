import {
  DefaultButton,
  IComboBoxOption,
  IconButton,
  Label,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import { Link, Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { ISimulationDto } from "../../Models/ISimulationDto";
import { UserInfo } from "../../Models/IUserInfo";
import { CalculateurServices } from "../../Services/CalculateurServices";
import CurrencyService from "../../Services/CurrencyService/CurrencyService";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { VoyageServices } from "../../Services/VoyageServices";
import { InformationItinerary } from "../InformationItinerary";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import { ServicesSupplementaire } from "../ServicesSupplementaire";
import {
  IRouteCalculatorProps,
  IRouteCalculatorState,
} from "./RouteCalculator.types";

export class RouteCalculatorComponent extends React.Component<
  IRouteCalculatorProps,
  IRouteCalculatorState
> {
  private _cookies = new Cookies();
  private _userInfo = this._cookies.get("userInfo") as UserInfo;
  private _anonymous: boolean;
  constructor(props: IRouteCalculatorProps) {
    super(props);
    this.state = {
      estResident: false,
      portOrigine: undefined,
      portDestination: undefined,
      estAllerRetour: false,
      dateDeDepart: undefined,
      dateDeDeparts: [],
      servicesSuppCode: [],
      nombreAine: 0,
      nombreAdulte: 0,
      nombreJeune: 0,
      nombreBebe: 0,
      nombreVehiculeConteneurisable: 0,
      nombreMotoConteneurisable : 0,
      navigate: false,
      noVoyage: "",
      simulationRetourResultat: {
        coutPassagers: 0,
        coutVehicules: 0,
        coutServices: 0,
        totauxDto: { sousTotal: 0, total: 0, tps: 0, tvq: 0 },
      },
    };
  }

  async componentDidMount() {
    this._anonymous = this._userInfo === undefined ? true : false;
    await this.getServiceSupplementaire();
  }

  render(): JSX.Element {
    if (this.state.navigate) {
      return <Navigate to="/reservationpassengervehicle" />;
    } else {
      return (
        <Stack style={{ marginLeft: "20px", marginBottom: "200px" }}>
          {this._anonymous ? (
            <Stack horizontalAlign="end" style={{ marginRight: "30px" }}>
              <Label
                style={{ cursor: "pointer" }}
                onClick={async () => {
                  i18n.changeLanguage(i18n.getLanguage());
                  await this.getServiceSupplementaire();
                }}
              >
                {i18n.t("app:translation")}
              </Label>
            </Stack>
          ) : (
            ""
          )}

          <NvxCustomBorderRadiusStack
            {...this.props}
            title={i18n.t("Calculator:Title:Cal")}
            render={
              <Stack style={{ marginLeft: "20px", marginBottom: "20px" }}>
                <InformationItinerary
                  forCalculator={true}
                  portOrigine={this.state.portOrigine}
                  portDestination={this.state.portDestination}
                  estAllerRetour={this.state.estAllerRetour}
                  trajetOrigineDestinationSelect={
                    this.state.portOrigine !== undefined &&
                    this.state.portDestination !== undefined
                  }
                  dateDeDepart={this.state.dateDeDepart}
                  dateDeDeparts={this.state.dateDeDeparts}
                  updatePortOrigini={async (v) => {
                    if (v !== undefined) {
                      this.setState({ portOrigine: v });
                      if (this.state.portDestination !== undefined) {
                        await this.getDateDepart( v.id, this.state.portDestination.id);
                      }
                      if (parseInt(this.state.portOrigine.id) > parseInt(this.state.portDestination.id)){
                        this.setState({ estAllerRetour: false });                    
                      }
                    }
                  }}
                  updateIsResident={(v) => {
                    if (v !== undefined) {
                      this.setState({ estResident: v });
                    }
                  }}
                  updatePortDestination={async (v) => {
                    if (v !== undefined) {
                      this.setState({ portDestination: v });
                      if (this.state.portOrigine !== undefined) {
                        await this.getDateDepart(this.state.portOrigine.id,v.id);
                      }
                      if (parseInt(this.state.portOrigine.id) > parseInt(this.state.portDestination.id)){
                        this.setState({ estAllerRetour: false });                    
                      }
                    }
                  }}
                  updateEstAllerRetour={(v) => {
                    if (v !== undefined) {
                      this.setState({ estAllerRetour: v });
                      if(v)
                      {
                        this.setState({ nombreVehiculeConteneurisable: 0 });
                        this.setState({ nombreMotoConteneurisable: 0 });
                      }
                    }
                  }}
                  updateDateDeDepart={(v) => {
                    if (v !== undefined) {
                      this.setState({ dateDeDepart: v });
                    }
                  }}
                  {...this.props}
                />
                {this.renderSelectPassenger()}
                {this.renderSelectVehicle()}
                <ServicesSupplementaire
                  vehiculeSelected={true}
                  passagerSelected={true}
                  updateAllServiceSupplementaire={(ss) => {
                    this.setState({ servicesSuppCode: ss });
                  }}
                  serviceSupplementaires={this.state.servicesSuppCode}
                  {...this.props}
                  updateServiceSupplementaire={(
                    serviceSupplementaire,
                    value
                  ) => {
                    let sscs = this.state.servicesSuppCode;
                    sscs.filter(
                      (x) => x.code === serviceSupplementaire.code
                    )[0].isCheck = value;

                    this.setState({ servicesSuppCode: sscs });
                  }}
                />
              </Stack>
            }
          />
          {this.renderSommaire()}
          <Stack
            horizontal={!this.props.mobile}
            style={{ width: "95%", marginTop: "25px" }}
            horizontalAlign="space-between"
          >
            <Stack
              style={{
                border: "1px solid black",
                borderRadius: 15,
                maxWidth: !this.props.mobile ? "600px" : "100%",
                marginBottom: !this.props.mobile ? "" : "20px",
              }}
            >
              <Label style={{ margin: "20px", color: "red" }}>
                {i18n.t("Calculator:MontantApprox:Advert")}
                <br />
                {i18n.t("Calculator:btn:PourPMOnly")}
              </Label>
            </Stack>
            <Stack horizontal>
              <DefaultButton
                onClick={() => {
                  this.setCookie();
                  this.setState({ navigate: true });
                }}
                disabled={!this.enable()}
                style={{
                  color: "white",
                  backgroundColor: !this.enable()
                    ? this.props.theme.palette.neutralLight
                    : this.props.theme.palette.blueDark,
                  borderRadius: 10,
                  height: "50px",
                  width: this.props.mobile ? "170px" : "250px",
                }}
              >
                {i18n.t("Calculator:btn:Reserver")}
              </DefaultButton>

              <DefaultButton
                disabled={!this.enable()}
                style={{
                  color: "white",
                  backgroundColor: !this.enable()
                    ? this.props.theme.palette.neutralLight
                    : this.props.theme.palette.blueDark,
                  borderRadius: 10,
                  height: "50px",
                  marginLeft: "15px",
                  width: this.props.mobile ? "170px" : "250px",
                }}
                onClick={() => {
                  this.calcul();
                }}
              >
                {i18n.t("Calculator:btn:Calculer")}
              </DefaultButton>
            </Stack>
          </Stack>
        </Stack>
      );
    }
  }

  enable() {
    return (
      this.state.portOrigine !== undefined &&
      this.state.portDestination !== undefined &&
      this.state.dateDeDepart !== undefined
    );
  }

  setCookie() {
    const cookies = new Cookies();
    cookies.set("infoItineraire", {
      portDestination: this.state.portDestination,
      portOrigine: this.state.portOrigine,
      dateDeDepart: this.state.dateDeDepart,
      estAllerRetour: this.state.estAllerRetour
    });
  }
  async calcul() {
    LoadingServices.setLoading("Calcul", true);
    let ssc: string[] = [];

    this.state.servicesSuppCode.forEach((x) => {
      if (x.isCheck) {
        ssc.push(x.code);
      }
    });
    this.setCookie();

    let rc: ISimulationDto = {
      noPortOrigine: this.state.portOrigine.id,
      noPortDestination: this.state.portDestination.id,
      estAllerRetour: this.state.estAllerRetour,
      noVoyage: this.state.dateDeDepart.id.toString(),
      dateDeDepart: new Date(this.state.dateDeDepart.text.toString()),
      servicesSuppCode: ssc,
      nombreAine: this.state.nombreAine,
      nombreAdulte: this.state.nombreAdulte,
      nombreJeune: this.state.nombreJeune,
      nombreBebe: this.state.nombreBebe,
      nombreVehiculeConteneurisable: this.state.nombreVehiculeConteneurisable,
      nombreMotoConteneurisable: this.state.nombreMotoConteneurisable,
      estResident: this.state.estResident,
    };

    let srr = await CalculateurServices.GetSimulerReservation(rc);

    this.setState({ simulationRetourResultat: srr });

    LoadingServices.setLoading("Calcul", false);
  }

  renderSelectVehicle() {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("Calculator:Title:Vehicule")}
        render={
          <Stack>
            <Stack >
            {this.state.estAllerRetour  ? (
          <Stack style={{ marginLeft: "25px", marginBottom:"10px" }}>
                  <Label style={{ color: "red" }}>
                  {i18n.t("Calculator:label:AvertissementVehiculeAllerRetour")}
                  </Label>
                </Stack>
              ) : (
                ""
              )}
            </Stack>
            <Stack horizontal verticalAlign="center">
              <IconButton
                size={50}
                disabled = {this.state.estAllerRetour}
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  height: 50,
                  width: 50,
                  marginRight: this.props.mobile ? "10px" : "40px",
                }}
                iconProps={{ iconName: "Blocked12" }}
                onClick={() => {
                  if (this.state.nombreVehiculeConteneurisable > 0) {
                    this.setState({ nombreVehiculeConteneurisable: this.state.nombreVehiculeConteneurisable - 1 });
                  }
                }}
              />
              <Label style={{ marginLeft: this.props.mobile ? "5px" : "20px", minWidth: this.props.mobile ? "150px" : "200px", }}>
                {i18n.t("Calculator:label:Vehicuel")}
              </Label>
              <Label style={{ marginLeft: "20px" }}>{this.state.nombreVehiculeConteneurisable}</Label>
              <IconButton
                disabled = {this.state.estAllerRetour}
                style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  this.setState({ nombreVehiculeConteneurisable: this.state.nombreVehiculeConteneurisable + 1 });
                }}
              />
            </Stack>

            <Stack horizontal verticalAlign="center">
              <IconButton
                disabled = {this.state.estAllerRetour}
                size={50}
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  height: 50,
                  width: 50,
                  marginRight: this.props.mobile ? "10px" : "40px",
                }}
                iconProps={{ iconName: "Blocked12" }}
                onClick={() => {
                  if (this.state.nombreMotoConteneurisable > 0) {
                    this.setState({ nombreMotoConteneurisable: this.state.nombreMotoConteneurisable - 1 });
                  }
                }}
              />
              <Label
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  minWidth: this.props.mobile ? "150px" : "200px",
                }}
              >
                {i18n.t("Calculator:label:Moto")}
              </Label>
              <Label style={{ marginLeft: "20px" }}>{this.state.nombreMotoConteneurisable}</Label>
              <IconButton
                disabled = {this.state.estAllerRetour}
                style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  this.setState({ nombreMotoConteneurisable: this.state.nombreMotoConteneurisable + 1 });
                }}
              />
            </Stack>
          </Stack>
        }
      />
    );
  }

  renderSommaire() {
    return (
      <Stack
        style={{
          border: "1px solid black",
          borderRadius: 10,
          width: "95%",
          marginTop: "30px",
          backgroundColor: this.props.theme.palette.blueDark,
          height: this.props.mobile ? "" : "100px",
        }}
        horizontal={!this.props.mobile}
        horizontalAlign="space-between"
      >
        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:Passager")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {CurrencyService.ToDollars(this.state.simulationRetourResultat.coutPassagers)}
          </Label>
        </Stack>
        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:Vehicule")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {CurrencyService.ToDollars(this.state.simulationRetourResultat.coutVehicules)}
          </Label>
        </Stack>

        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:Service")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {CurrencyService.ToDollars(this.state.simulationRetourResultat.coutServices)}
          </Label>
        </Stack>


        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:SousTotal")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {this.state.simulationRetourResultat.totauxDto === undefined
              ? CurrencyService.ToDollars(0)
              : CurrencyService.ToDollars(this.state.simulationRetourResultat.totauxDto.sousTotal)}            
          </Label>
        </Stack>
        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:Tps")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {this.state.simulationRetourResultat.totauxDto === undefined
              ? CurrencyService.ToDollars(0)
              : CurrencyService.ToDollars(this.state.simulationRetourResultat.totauxDto.tps)}
            
          </Label>
        </Stack>
        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:Tvq")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {this.state.simulationRetourResultat.totauxDto === undefined
              ? CurrencyService.ToDollars(0)
              : CurrencyService.ToDollars(this.state.simulationRetourResultat.totauxDto.tvq)}
            
          </Label>
        </Stack>
        <Stack
          style={{ marginLeft: "20px", marginRight: "20px" }}
          verticalAlign={!this.props.mobile ? "center" : "start"}
          horizontalAlign={!this.props.mobile ? "center" : "start"}
        >
          <Label
            style={{
              color: "white",
              minWidth: this.props.mobile ? "100px" : "",
            }}
          >
            {i18n.t("Calculator:Somaire:MontantPayer")}
          </Label>
          <Label
            style={{
              color: this.props.theme.palette.blueLight,
              marginLeft: "15px",
            }}
          >
            {this.state.simulationRetourResultat.totauxDto === undefined
              ? CurrencyService.ToDollars(0)
              : CurrencyService.ToDollars(this.state.simulationRetourResultat.totauxDto.total)}
            
          </Label>
        </Stack>
      </Stack>
    );
  }

  renderSelectPassenger() {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={i18n.t("Calculator:Title:Passager")}
        render={
          <Stack>
            <Stack horizontal verticalAlign="center" style={{ width: "100%" }}>
              <IconButton
                size={50}
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  height: 50,
                  width: 50,
                  marginRight: this.props.mobile ? "10px" : "40px",
                }}
                iconProps={{ iconName: "Blocked12" }}
                onClick={() => {
                  if (this.state.nombreAine > 0) {
                    this.setState({ nombreAine: this.state.nombreAine - 1 });
                  }
                }}
              />
              <Label
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  minWidth: this.props.mobile ? "150px" : "200px",
                }}
              >
                {i18n.t("Calculator:PassagerLabel:Aine")}
              </Label>
              <Label style={{ marginLeft: "20px" }}>{this.state.nombreAine}</Label>
              <IconButton
                style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  this.setState({ nombreAine: this.state.nombreAine + 1 });
                }}
              />
            </Stack>

            <Stack horizontal verticalAlign="center">
              <IconButton
                size={50}
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  height: 50,
                  width: 50,
                  marginRight: this.props.mobile ? "10px" : "40px",
                }}
                iconProps={{ iconName: "Blocked12" }}
                onClick={() => {
                  if (this.state.nombreAdulte > 0) {
                    this.setState({ nombreAdulte: this.state.nombreAdulte - 1 });
                  }
                }}
              />
              <Label
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  minWidth: this.props.mobile ? "150px" : "200px",
                }}
              >
                {i18n.t("Calculator:PassagerLabel:Adulte")}
              </Label>
              <Label style={{ marginLeft: "20px" }}>
                {this.state.nombreAdulte}
              </Label>
              <IconButton
                style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  this.setState({ nombreAdulte: this.state.nombreAdulte + 1 });
                }}
              />
            </Stack>

            <Stack horizontal verticalAlign="center">
              <IconButton
                size={50}
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  height: 50,
                  width: 50,
                  marginRight: this.props.mobile ? "10px" : "40px",
                }}
                iconProps={{ iconName: "Blocked12" }}
                onClick={() => {
                  if (this.state.nombreJeune > 0) {
                    this.setState({ nombreJeune: this.state.nombreJeune - 1 });
                  }
                }}
              />
              <Label
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  minWidth: this.props.mobile ? "150px" : "200px",
                }}
              >
                {i18n.t("Calculator:PassagerLabel:Jeunes")}
              </Label>
              <Label style={{ marginLeft: "20px" }}>
                {this.state.nombreJeune}
              </Label>
              <IconButton
                style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  this.setState({ nombreJeune: this.state.nombreJeune + 1 });
                }}
              />
            </Stack>

            <Stack horizontal verticalAlign="center">
              <IconButton
                size={50}
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  height: 50,
                  width: 50,
                  marginRight: this.props.mobile ? "10px" : "40px",
                }}
                iconProps={{ iconName: "Blocked12" }}
                onClick={() => {
                  if (this.state.nombreBebe > 0) {
                    this.setState({ nombreBebe: this.state.nombreBebe - 1 });
                  }
                }}
              />
              <Label
                style={{
                  marginLeft: this.props.mobile ? "5px" : "20px",
                  minWidth: this.props.mobile ? "150px" : "200px",
                }}
              >
                {i18n.t("Calculator:PassagerLabel:bebe")}
              </Label>
              <Label style={{ marginLeft: "20px" }}>{this.state.nombreBebe}</Label>
              <IconButton
                style={{ marginLeft: this.props.mobile ? "10px" : "20px" }}
                iconProps={{ iconName: "AddTo" }}
                onClick={() => {
                  this.setState({ nombreBebe: this.state.nombreBebe + 1 });
                }}
              />
            </Stack>
          </Stack>
        }
      />
    );
  }

  async getServiceSupplementaire() {
    LoadingServices.setLoading("ServicesSupplementaire", true);
    const noOrigineParDefaut = 1;
    const noDestinationParDefaut = 3;
    let ss = await ReservationServices.GetServicesSupplementaires(noOrigineParDefaut,noDestinationParDefaut);
    this.setState({ servicesSuppCode: ss });
    LoadingServices.setLoading("ServicesSupplementaire", false);
  }

  async getDateDepart(portOrigineId: string, portDestinationId: string) {
    let dateDeparts: IComboBoxOption[] = [];
    let d = await VoyageServices.GetDeparts(portOrigineId, portDestinationId);
    d.forEach((x) => {
      dateDeparts.push({
        key: x.noPeriode,
        text: x.dateDepartLibelle,
        id: x.noVoyage,
      });
    });
    this.setState({ dateDeDeparts: dateDeparts });
  }
}
