import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import i18n from "../../Services/i18n";
import { ReservationServices } from "../../Services/ReservationServices";
import { getAppHeaderClassNames } from "./AppHeader.styles";
import { IAppHeaderProps, IAppHeaderState } from "./AppHeader.types";

export class AppHeaderComponent extends React.Component<
  IAppHeaderProps,
  IAppHeaderState
> {
  constructor(props: IAppHeaderProps) {
    super(props);
    this.state = { nombreItem: 0 };
  }

  componentDidUpdate(
    prevProps: Readonly<IAppHeaderProps>,
    prevState: Readonly<IAppHeaderState>,
    snapshot?: any
  ): void {
    if (this.props !== prevProps && this.props.isConnected) {
      this.getNombreItemPanier();
    }
  }
  async getNombreItemPanier() {
    let n = await ReservationServices.GetNombreItemPanier();
    this.setState({ nombreItem: n });
  }

  async componentDidMount() {
    if (this.props.isConnected) {
      this.getNombreItemPanier();
    }
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getAppHeaderClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <Stack
        horizontal
        horizontalAlign="space-between"
        verticalAlign="center"
        className={classNames.borderBottom}
      >
        <Stack horizontal>
          <Link style={{ margin: "10px" }} to="/">
            <img className={classNames.logo} src="/img/logo-Bella.svg" />
          </Link>
        </Stack>

        {this.props.isConnected ? (
          <Stack
            horizontal
            verticalAlign="center"
            style={{
              marginRight: "10px",
              marginBottom: "5px",
              marginTop: "5px",
            }}
          >
            <Stack
              style={{ marginLeft: "10px", marginRight: "5px", padding: "0px" }}
              verticalAlign="space-between"
              horizontalAlign="end"
            >
              <a href="/cart" onClick={() => {window.location.href="/cart"}} style={{ fontWeight: "bold", fontSize: "16px"}}>
                {i18n.t("AppHeader:label:Cart")}({this.state.nombreItem})
              </a>
              <Stack horizontal>
                <Label style={{ marginLeft: "10px", padding: "0px" }}>
                  {i18n.t("AppHeader:label:UserCo")}
                </Label>
                <Label style={{ marginLeft: "10px", padding: "0px" }}>
                  {this.props.userInfo.compteEntete.prenom}
                </Label>
                <Label style={{ marginLeft: "10px", padding: "0px" }}>
                  {this.props.userInfo.compteEntete.nom}
                </Label>
              </Stack>

              <Label
                style={{
                  fontStyle: "italic",
                  cursor: "pointer",
                  padding: "0px",
                }}
                onClick={() => {
                  this.props.Disconnect();
                }}
              >
                {i18n.t("AppHeader:label:logOut")}
              </Label>
            </Stack>

            <img
              className={classNames.logo}
              style={{ marginTop: "3px" }}
              src="/img/UserLogo.png"
            />
          </Stack>
        ) : (
          <Stack horizontal style={{ marginRight: "50px" }}>
            <Link to="/signup" style={{ marginLeft: "10px" }}>
              {i18n.t("AppHeader:label:SignUp")}
            </Link>
            <Link to="/signin" style={{ marginLeft: "10px" }}>
              {i18n.t("AppHeader:label:SignIn")}
            </Link>
          </Stack>
        )}
      </Stack>
    );
  }
}
