import { styled } from "@fluentui/react/lib/Utilities";
import { IHistoryProps } from "./History.types";
import {
  getStyles,
  IHistoryStyleProps,
  IHistoryStyles,
} from "./History.styles";
import { HistoryComponent } from "./History.base";

/**
 * History
 */
export const History = styled<
  IHistoryProps,
  IHistoryStyleProps,
  IHistoryStyles
>(HistoryComponent, getStyles);
