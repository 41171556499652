import { styled } from "@fluentui/react/lib/Utilities";
import { IFooterProps } from "./Footer.types";
import { getStyles, IFooterStyleProps, IFooterStyles } from "./Footer.styles";
import { FooterComponent } from "./Footer.base";

/**
 * Footer
 */
export const Footer = styled<IFooterProps, IFooterStyleProps, IFooterStyles>(
  FooterComponent,
  getStyles
);
