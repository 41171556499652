import {
  Checkbox,
  IconButton,
  IProcessedStyleSet,
  Label,
  Modal,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { IVehiculeSelectedReservation } from "../../Models/IVehiculeSelectedReservation";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { StylingServices } from "../../Services/StylingServices";
import { NvxCustomBorderRadiusStack } from "../NvxCustomBorderRadiusStack";
import {
  getReservationVehicleClassNames,
  IReservationVehicleStyles,
} from "./ReservationVehicle.styles";
import {
  IReservationVehicleProps,
  IReservationVehicleState,
} from "./ReservationVehicle.types";

export class ReservationVehicleComponent extends React.Component<
  IReservationVehicleProps,
  IReservationVehicleState
> {
  private _iconButtonStyles = {
    root: {
      color: this.props.theme.palette.neutralPrimary,
    },
    rootHovered: {
      color: this.props.theme.palette.neutralDark,
    },
  };
  constructor(props: IReservationVehicleProps) {
    super(props);
    this.state = {
      vehicleSelectedReservation: [],
      isLoading: true,
      noVehiculeDisponnible: false,
      showModalMd: false,
    };
  }

  async getVehicles() {
    LoadingServices.setLoading("getVehicles", true);

    let v = await ReservationServices.GetVehiculeDisponnible(
      this.props.noPeriode
    );
    let vehicleSelectedReservation: IVehiculeSelectedReservation[] = [];
    if (!v.delaiDepasserPourVehicule) {
      v.listeVehicules.forEach((x) => {
        if (!x.enAttenteApprobation) {
          let vsr: IVehiculeSelectedReservation = {
            vehiculeId: x.id,
            neContientMatiereDangereuse: false,
            particularite: "",
            showParticularity: false,
            selected: false,
            vehiculeLibelle: x.vehiculeLibelle,
          };
          let vehicle = this.props.vehicleSelectedReservation.filter(
            (k) => k.vehiculeId === x.id
          )[0];
          if (vehicle) {
            vsr.neContientMatiereDangereuse =
              vehicle.neContientMatiereDangereuse;
            vsr.particularite = vehicle.particularite;
            vsr.selected = vehicle.selected;
          }
          vehicleSelectedReservation.push(vsr);
        }
      });
    }

    this.setState({
      vehicleSelectedReservation: vehicleSelectedReservation,
      isLoading: false,
      noVehiculeDisponnible: v.delaiDepasserPourVehicule,
    });
    LoadingServices.setLoading("getVehicles", false);
  }

  async componentDidMount() {
    await this.getVehicles();
    await this.props.getPlaceDispo()
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getReservationVehicleClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    let color = "red";
    if (this.props.informationSurreservation !== null) {
      color = StylingServices.GetGradationColor(this.props.informationSurreservation.nombrePlaceUtilise, this.props.informationSurreservation.nombrePlaceMaximum)
    }
    
    if (this.state.isLoading) {
      return <Stack></Stack>;
    } else if (this.state.noVehiculeDisponnible) {
      return (
        <Label style={{ color: this.props.theme.palette.red }}>
          {i18n.t("Vehicle:label:Under12HoursVehicle")}
        </Label>
      );
    } else {
      return (
        <Stack
          style={{
            marginLeft: "10px",
          }}
        >
          {this.props.informationSurreservation !== null ? (
            <Stack style={{ marginTop: "15px" }}>
              <Label style={{ color: color }}>                
                {i18n.t("Message:VehiculePlaceDispo1")}
                {this.props.informationSurreservation.nombrePlaceDisponible}
                {i18n.t("Message:VehiculePlaceDispo2")}
              </Label>
            </Stack>
          ) : (
            ""
          )}

          {this.props.informationSurreservation.nombrePlaceDisponible < this.state.vehicleSelectedReservation.filter(x=>x.selected).length ? (
            <Stack style={{ marginTop: "15px" }}>
              <Label style={{ color: "red" }}>
                {i18n.t("RPV:Message:TooManySelectedVehicle1")}
                {this.props.informationSurreservation.nombrePlaceDisponible}
                {i18n.t("RPV:Message:TooManySelectedVehicle2")}
              </Label>
            </Stack>
          ) : (
            ""
          )}

          <Stack>
            {this.modalInfoMD(classNames)}
            {this.props.vehicleSelectedReservation.length > 0
              ? this.renderUnderComponent(
                  i18n.t("Vehicle:title:Conteneurisable"),
                  this.props.vehicleSelectedReservation
                )
              : ""}
          </Stack>
        </Stack>
      );
    }
  }

  renderUnderComponent(
    title: string,
    vehicles: IVehiculeSelectedReservation[]
  ) {
    return (
      <NvxCustomBorderRadiusStack
        {...this.props}
        title={title}
        render={
          <Stack
            style={{
              marginLeft: "5px",
              marginTop: "20px",
              marginRight: "20px",
              marginBottom: "20px",
            }}
          >
            {this.renderVehicles(vehicles)}
          </Stack>
        }
      />
    );
  }

  renderVehicles(vehicles: IVehiculeSelectedReservation[]): JSX.Element[] {
    let element = vehicles.map((x, i) => {
      return (
        <Stack style={{ marginLeft: "15px" }} key={x.vehiculeId}>
          <Stack horizontal horizontalAlign="space-between">
            <Label>
              - {x.vehiculeLibelle}
            </Label>

            <Stack>
              <Checkbox
                checked={x.selected}
                onChange={(e, value) => {
                  let vehicles = this.props.vehicleSelectedReservation;
                  x.showParticularity = !x.showParticularity;
                  if (!value) x.neContientMatiereDangereuse = false;
                  this.props.updateVehiculeSelect(x.vehiculeId, value);
                  this.setState({ vehicleSelectedReservation: vehicles });
                }}
              />
            </Stack>
          </Stack>
          {x.showParticularity ? (
            <Stack
              style={{
                marginLeft: "15px",
                marginBottom: "25px",
              }}
            >
              <Stack>
                <Stack style={{ minHeight: "30px" }}>
                  {x.selected && !x.neContientMatiereDangereuse ? (
                    <Label style={{ color: this.props.theme.palette.red }}>
                      {i18n.t("RV:Label:MatiereDangereuseMsgError")}
                    </Label>
                  ) : (
                    ""
                  )}{" "}
                </Stack>

                <Stack horizontal verticalAlign="center">
                  <IconButton
                    style={{ marginRight: "20px" }}
                    iconProps={{ iconName: "FeedbackRequestSolid" }}
                    styles={this._iconButtonStyles}
                    onClick={() => {
                      this.setState({ showModalMd: true });
                    }}
                  />
                  <Label>{i18n.t("RV:Label:MatiereDangereuse")}</Label>
                  <Stack style={{ marginLeft: "15px" }}>
                    <Checkbox
                      checked={x.neContientMatiereDangereuse}
                      onChange={(e, v) => {
                        this.props.updateVehiculeCMD(x.vehiculeId, v);
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>

              <Stack>
                <Label>{i18n.t("RV:Label:Particularite")}</Label>
                <TextField
                  value={x.particularite}
                  multiline
                  onChange={(e, v) => {
                    if (v !== undefined && v.length < 1000) {
                      this.props.updateVehiculeParticularite(x.vehiculeId, v);
                    }
                  }}
                />
              </Stack>
            </Stack>
          ) : (
            ""
          )}
        </Stack>
      );
    });
    return element;
  }

  modalInfoMD(classNames: IProcessedStyleSet<IReservationVehicleStyles>) {
    return (
      <Modal isOpen={this.state.showModalMd}>
        <Stack
          style={{
            minWidth: this.props.mobile ? window.innerWidth - 50 : "",
            minHeight: this.props.mobile ? window.innerHeight - 50 : "",
            marginBottom: "30px",
          }}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            className={classNames.modalLine}
            style={{
              minWidth: this.props.mobile ? "" : "500px",
              maxWidth: "700px",
            }}
          >
            <Stack horizontal>
              <h2 style={{ marginLeft: "15px" }}>
                <img className={classNames.logo} src="/img/logo-Bella.svg" />
              </h2>
            </Stack>
            <Stack horizontalAlign="end">
              <IconButton
                className={classNames.iconButtonStyles}
                iconProps={{ iconName: "Cancel" }}
                ariaLabel="Close popup modal"
                onClick={() => {
                  this.setState({ showModalMd: false });
                }}
              />
            </Stack>
          </Stack>
          <Stack
            style={{
              marginLeft: "20px",
              maxWidth: "600px",
              marginRight: "20px",
              marginTop: "20px",
            }}
            horizontalAlign="center"
          >
            <Label>{i18n.t("RV:PopUp:MatiereDangereuse1")}</Label>
            <ul style={{ marginTop: "10px" }}>
              <li>{i18n.t("RV:PopUp:li1")}</li>
              <li>{i18n.t("RV:PopUp:li2")}</li>
              <li>{i18n.t("RV:PopUp:li3")}</li>
              <li>{i18n.t("RV:PopUp:li4")}</li>
              <li>{i18n.t("RV:PopUp:li5")}</li>
              <li>{i18n.t("RV:PopUp:li6")}</li>
              <li>{i18n.t("RV:PopUp:li7")}</li>
              <li>{i18n.t("RV:PopUp:li8")}</li>
              <li>{i18n.t("RV:PopUp:li9")}</li>
            </ul>
            <Label style={{ marginTop: "10px", color: "red" }}>
              {i18n.t("RV:PopUp:RedMsg")}
            </Label>
          </Stack>
        </Stack>
      </Modal>
    );
  }
}
