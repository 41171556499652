import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface ICommunicationInformationSubComponentStyles {}

export interface ICommunicationInformationStyles
  extends IAppBaseStyles<ICommunicationInformationSubComponentStyles> {}

export interface ICommunicationInformationStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getCommunicationInformationClassNames = (
  styles?: IStyleFunctionOrObject<
    ICommunicationInformationStyleProps,
    ICommunicationInformationStyles
  >,
  props?: ICommunicationInformationStyleProps
): IComponentBaseClassNames<
  ICommunicationInformationStyleProps,
  ICommunicationInformationStyles,
  ICommunicationInformationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    ICommunicationInformationStyleProps,
    ICommunicationInformationStyles,
    ICommunicationInformationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  ICommunicationInformationStyleProps,
  ICommunicationInformationStyles
> = (
  props: ICommunicationInformationStyleProps
): ICommunicationInformationStyles => {
  return {
    root: ["app-CommunicationInformation", {}],
  };
};
