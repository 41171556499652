import { IStyleFunction, IStyleFunctionOrObject } from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IBasicInformationSubComponentStyles {}

export interface IBasicInformationStyles
  extends IAppBaseStyles<IBasicInformationSubComponentStyles> {}

export interface IBasicInformationStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getBasicInformationClassNames = (
  styles?: IStyleFunctionOrObject<
    IBasicInformationStyleProps,
    IBasicInformationStyles
  >,
  props?: IBasicInformationStyleProps
): IComponentBaseClassNames<
  IBasicInformationStyleProps,
  IBasicInformationStyles,
  IBasicInformationSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IBasicInformationStyleProps,
    IBasicInformationStyles,
    IBasicInformationSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IBasicInformationStyleProps,
  IBasicInformationStyles
> = (props: IBasicInformationStyleProps): IBasicInformationStyles => {
  return {
    root: ["app-BasicInformation", {}],
  };
};
