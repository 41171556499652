import { styled } from "@fluentui/react/lib/Utilities";
import { IContactUsProps } from "./ContactUs.types";
import {
  getStyles,
  IContactUsStyleProps,
  IContactUsStyles,
} from "./ContactUs.styles";
import { ContactUsComponent } from "./ContactUs.base";

/**
 * ContactUs
 */
export const ContactUs = styled<
  IContactUsProps,
  IContactUsStyleProps,
  IContactUsStyles
>(ContactUsComponent, getStyles);
