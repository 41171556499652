import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import { ComponentStylesLoader } from "../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../ComponentBase/IComponentBaseClassNames";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../Base";

export interface IAppSubComponentStyles {}

export interface IAppStyles extends IAppBaseStyles<IAppSubComponentStyles> {}

export interface IAppStyleProps extends IAppBaseStyleProps<IAppTokens> {}

export const getAppClassNames = (
  styles?: IStyleFunctionOrObject<IAppStyleProps, IAppStyles>,
  props?: IAppStyleProps
): IComponentBaseClassNames<
  IAppStyleProps,
  IAppStyles,
  IAppSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IAppStyleProps,
    IAppStyles,
    IAppSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<IAppStyleProps, IAppStyles> = (
  props: IAppStyleProps
): IAppStyles => {
  return {
    root: ["app-App", { height: "100%", width: "105%" }],
  };
};
