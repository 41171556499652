import { Label, Stack } from "@fluentui/react";
import * as React from "react";
import i18n from "../../Services/i18n";
import LoadingServices from "../../Services/LoadingServices/LoadingServices";
import { ReservationServices } from "../../Services/ReservationServices";
import { Reservations } from "../Reservations";
import {
  ICurrentBookingProps,
  ICurrentBookingState,
} from "./CurrentBooking.types";

export class CurrentBookingComponent extends React.Component<
  ICurrentBookingProps,
  ICurrentBookingState
> {
  constructor(props: ICurrentBookingProps) {
    super(props);
    this.state = {
      showModal: false,
      miniReservations: [],
      isLoading: true,
    };
  }

  async componentDidMount() {
    LoadingServices.setLoading("CurrentBooking", true);
    let rc = await ReservationServices.GetReservationCourantes();

    this.setState({ miniReservations: rc, isLoading: false });
    LoadingServices.setLoading("CurrentBooking", false);
  }

  render(): JSX.Element {
    if (this.state.isLoading) {
      return <Stack></Stack>;
    } else {
      return (
        <Stack style={{ marginBottom: "100px" }}>
          <Reservations
            {...this.props}
            miniReservations={this.state.miniReservations}
            isCurrent={true}
          />

<Stack
            style={{
              border: "1px solid black",
              borderRadius: 30,
              width: this.props.mobile ? "95%" : "94%",
              marginTop: "25px",
              marginLeft: this.props.mobile ? "10px" : "20px",
              backgroundColor: this.props.theme.palette.blueDark,
            }}
          >
            <h2 style={{ color: "white", marginLeft: "20px" }}>
              {i18n.t("CB:Label:PolitiquePsg")}
            </h2>
            <Label
              style={{
                color: "white",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign:"justify", 
                textJustify:"inter-word"
              }}
            >
              - {i18n.t("CB:Label:FirstRulePsg")}
            </Label>
            <Label
              style={{
                color: "white",
                marginLeft: "20px",
                marginBottom: "20px",
                marginRight: "20px",
                textAlign:"justify", 
                textJustify:"inter-word"
              }}
            >
              - {i18n.t("CB:Label:SecondRulePsg")}
            </Label>

            <h2 style={{ color: "white", marginLeft: "20px" }}>
              {i18n.t("CB:Label:PolitiqueVhc")}
            </h2>
            <Label
              style={{
                color: "white",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign:"justify", 
                textJustify:"inter-word"
              }}
            >
              - {i18n.t("CB:Label:FirstRuleVhc")}
            </Label>
            <Label
              style={{
                color: "white",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign:"justify", 
                textJustify:"inter-word"
              }}
            >
            - {i18n.t("CB:Label:SecondRuleVhc")}
            </Label>
            <Label
              style={{
                color: "white",
                marginLeft: "20px",
                marginBottom: "20px",
                marginRight: "20px",
                textAlign:"justify", 
                textJustify:"inter-word"
              }}
            >
              - {i18n.t("CB:Label:ThirdRuleVhc")}
            </Label>
          </Stack>
        </Stack>
      );
    }
  }
}
