import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IAppBaseStyleProps, IAppBaseStyles, IAppTokens } from "../../Base";
import { ComponentStylesLoader } from "../../ComponentBase/ComponentStylesLoader";
import { IComponentBaseClassNames } from "../../ComponentBase/IComponentBaseClassNames";

export interface IForgetPasswordSubComponentStyles {}

export interface IForgetPasswordStyles
  extends IAppBaseStyles<IForgetPasswordSubComponentStyles> {
  modalLine: IStyle;
  logo: IStyle;
  iconButtonStyles: IStyle;
}

export interface IForgetPasswordStyleProps
  extends IAppBaseStyleProps<IAppTokens> {}

export const getForgetPasswordClassNames = (
  styles?: IStyleFunctionOrObject<
    IForgetPasswordStyleProps,
    IForgetPasswordStyles
  >,
  props?: IForgetPasswordStyleProps
): IComponentBaseClassNames<
  IForgetPasswordStyleProps,
  IForgetPasswordStyles,
  IForgetPasswordSubComponentStyles
> => {
  return ComponentStylesLoader.getComponentClassNames<
    IForgetPasswordStyleProps,
    IForgetPasswordStyles,
    IForgetPasswordSubComponentStyles
  >(styles, props);
};

export const getStyles: IStyleFunction<
  IForgetPasswordStyleProps,
  IForgetPasswordStyles
> = (props: IForgetPasswordStyleProps): IForgetPasswordStyles => {
  return {
    logo: {
      maxHeight: 50,
    },
    modalLine: {
      "border-bottom-style": "solid",
      "border-bottom-width": "medium",
      "border-bottom-color": "darkblue",
    },
    iconButtonStyles: {
      root: {
        color: props.theme.palette.neutralPrimary,
        marginLeft: "auto",
        marginTop: "4px",
        marginRight: "2px",
      },
      rootHovered: {
        color: props.theme.palette.neutralDark,
      },
    },
  };
};
