import { Stack } from "@fluentui/react";
import * as React from "react";
import MediaQuery from "react-responsive";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import { LayoutBase } from "../Components/LayoutBase";
import { UserInfo } from "../Models/IUserInfo";
import { ConfirmationAccount } from "../Pages/ConfirmationAccount";
import { ForgetPassword } from "../Pages/ForgetPassword";
import { SignIn } from "../Pages/SignIn";
import { SignUp } from "../Pages/SignUp";
import i18n from "../Services/i18n";
import { getAppRouterClassNames } from "./AppRouter.styles";
import { IAppRouterProps, IAppRouterState } from "./AppRouter.types";

export class AppRouterComponent extends React.Component<
  IAppRouterProps,
  IAppRouterState
> {
  private _cookies = new Cookies();

  private _userInfo = this._cookies.get("userInfo") as UserInfo;
  constructor(props: IAppRouterProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const { classNames, subComponentStyles } = getAppRouterClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <Stack>
        <MediaQuery maxDeviceWidth={1224}>
          {(mobile) => (
            <MediaQuery minDeviceWidth={1224}>
              {(desktop) => (
                <BrowserRouter>
                  {this.props.maintenance.enMaintenance ? (
                    <Routes>
                      <Route
                        path="*"
                        element={
                          <LayoutBase
                            {...this.props}
                            mobile={mobile}
                            maintenance={this.props.maintenance}
                          />
                        }
                      />
                    </Routes>
                  ) : (
                    <Routes>
                      <Route
                        path="/confirmationaccount/*"
                        element={<ConfirmationAccount {...this.props} />}
                      />

                      <Route
                        path="/signup"
                        element={<SignUp {...this.props} mobile={mobile} />}
                      />
                      <Route
                        path="/forgetpassword"
                        element={
                          <ForgetPassword {...this.props} mobile={mobile} />
                        }
                      />
                      <Route
                        path="/signin"
                        element={<SignIn {...this.props} mobile={mobile} />}
                      />
                      <Route
                        path="*"
                        element={<LayoutBase {...this.props} mobile={mobile} />}
                      />
                    </Routes>
                  )}
                </BrowserRouter>
              )}
            </MediaQuery>
          )}
        </MediaQuery>
      </Stack>
    );
  }
}
