import axios from "axios";
import { IVille } from "../Models/IVille";
import { apiUrl } from "./ApiService";
import i18n from "./i18n";
export class VilleServices {
  public static async GetVille(): Promise<IVille[]> {
    let result: any | undefined;
    try {
      const x = await axios.get<any>(
        `${apiUrl}/api/ville/get-villes-resident/${i18n.getLanguage()}`
      );
      result = x.data;
    } catch (error) {
      result = (error as any)?.response?.status;
    }
    return result;
  }
}
